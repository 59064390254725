@import './config.scss';


.subMain {
    user-select: none;
    margin-top: 110px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // .subHeader{
    //     position: fixed;
    //     top: 0;
    //     z-index: 2;
    //     width: 100vw;
    //     padding: 10px;
    //     background-color: $w;
    //     box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    //     display: flex;
    //     align-items:center;
    //     justify-content: center;
    //     .subHdImg{
    //         pointer-events: none;
    //         height: 80px;
    //     }
    //     .subTxt {
    //         margin: 0px 20px;;
    //         padding: 5px 10px;
    //         background-color: $Wpnk;
    //         border:1px solid $Wpnk;
    //         color: $pnk;
    //         box-shadow: 0 0 10px $pnk  ;
    //         border-radius: 10px;
    //         font-weight: 500;
    //         transition: 600ms;
    //         font-family: sans-serif;
    //         margin-top: 0px;
    //     }
    //     .subTxt:hover{
    //         box-shadow: unset;
    //         border:1px solid $pnk
    //     }
    // }
    .subTtl{
        color: $w;
        align-self: center;
        margin: 10px 0px;
        padding: 10px 20px;
        text-align: center;
        background-color: #108d98;
        font-weight: 600;
        font-family: sans-serif;
        font-size: 18px;
        border-radius: 15px;
    }
    .proceedBtn{
        cursor: pointer;
        text-align: center;
        font-size: 24px;
        background-color: $pnk;
        color: $w;
        padding: 10px 20px;
        align-self: center;
        border-radius: 20px;
        box-shadow: 0 0 20px $pnk ;
        margin:10px;
        margin-bottom: 100px;
        transition: 0.8s;
    }
    .proceedBtn:hover{
        box-shadow: none;
    }
}