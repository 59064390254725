@import "./config.scss";

.progCon {
  width: 100%;
  background-color: $GW;
  border-radius: 10px;
  .progBar {
    background-color: $pnk;
    box-shadow: 0 0 4px $pnk;
    border-radius: 10px;
    text-align: right;
    .progTxt {
      padding-inline: 12px;
      vertical-align: middle;
      color: $b;
      font-weight: 600;
      font-size: small;
    }
  }
}

.progChart {
  margin-block: 20px;
  width: 55%;
  height: 400px;
  @include media(mobile, tabletPo) {
    width: 90%;
    height: 400px;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 55%;
    height: 600px;
  }
}

.pieChart {
  width: 100%;
  aspect-ratio: 1;
}

.OaProgChart {
  margin-block: 20px;
  width: 94%;
  height: 400px;
  @include media(mobile, tabletPo) {
    height: 400px;
  }
  @include media(desktop, desktop-big, tabletLa) {
    height: 600px;
  }
}

.EaGuageChart {
  margin-block: 20px;
  width: 90%;
  @include media(mobile, tabletPo) {
    width: 100%;
    aspect-ratio: 1.5;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 30%;
    aspect-ratio: 2;
  }
}

.mockProgChart {
  margin-block: 0px 10px;
  width: 96%;
  height: 60vh;
}

.deOvrProg {
  aspect-ratio: 1;
  width: 100%;
}

.deProgChart {
  margin-block: 20px;
  width: 90%;
  height: 600px;
}
