@import "./config.scss";

.mvpMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .srchModDiv {
    display: flex;
    width: 50%;
    justify-content: space-around;
    .srchMod {
      cursor: pointer;
      background-color: $GW;
      color: $gr;
      border-radius: 20px;
      border: 1px solid $gr;
      box-shadow: 0 0 10px $gr;
      padding: 10px 20px;
      transition: 600ms;
      font-size: large;
      font-weight: 600;
    }
    .srchMod:hover {
      background-color: $Wpnk;
      color: $pnk;
      border: 1px solid $pnk;
      box-shadow: 0 0 10px $pnk;
    }
    .alt,
    .alt:hover {
      box-shadow: none;
      background-color: $pnk;
      color: $w;
      border: 1px solid $pnk;
    }
  }
  .mvpRes {
    display: flex;
    flex-wrap: wrap;
    width: 96%;
    justify-content: space-evenly;
    max-height: 72vh;
    overflow-y: scroll;
    overflow-x: hidden;
    .errD {
      margin-block: 6.5vh;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .errNo {
        text-align: center;
        background-color: $GW;
        align-self: center;
        font-size: larger;
        padding: 10px 20px;
        border-radius: 10px;
        border: 1px solid $dGr;
        color: $dGr;
      }
    }
  }
}

.mvpEduProMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .eduTtl {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px $lG;
    background-color: $w;
    font-size: large;
    font-weight: 600;
    color: $gr;
    border: 1px solid $lG;
  }
  .eduProDiv {
    margin-block: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 10px;
    width: 94%;
    border-radius: 20px;
    background-color: #fcedf050;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    .eduImgDiv {
      width: 200px;
      display: flex;
      .eduImg {
        align-self: center;
        width: 72%;
        background-color: $b;
        aspect-ratio: 1;
        border-radius: 100px;
      }
    }
    .eduInfoDiv {
      width: 240px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .eduName {
        font-size: x-large;
        font-weight: 600;
        color: $pnk;
      }
      .eduSub {
        margin-top: 0px;
        font-size: larger;
        font-weight: 600;
        letter-spacing: 1px;
        color: $dGr;
      }
    }
    .eduAboutDiv {
      width: 500px;
      // text-align: center;
      .abtTtl {
        text-align: center;
      }
    }
  }
}

////////////////////////////////////////////////
// .container {
//   display: flex;
// }

// .box {
//   border: solid 1px $b;
//   background: #00c851;
//   flex: 1;
//   transition: transform 300ms ease 100ms;
//   &:after {
//     content: "";
//     display: flex;
//     padding-top: 70%;
//   }
//   &:hover {
//     transform: scale(1.5);
//   }
// }

// .box:hover ~ .box {
//   transform: translateX(25%);
// }
