@import "./config.scss";

.ebStatDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .ebStatItem {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ebStatIcn {
      color: $lG;
      font-size: 50px;
    }
    .ebStat {
      padding-top: 20px;
      padding-bottom: 5px;
      font-size: 26px;
      color: $pnk;
      font-family: sans-serif;
      font-weight: 600;
    }
    .ebStatTxt {
      font-size: 20px;
      font-weight: 500;
      font-family: sans-serif;
    }
  }
}

.gnrItm {
  margin: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $GW;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  @include media(mobile, tabletPo) {
    margin: 5px;
  }
  @include media(desktop, desktop-big, tabletLa) {
    margin: 40px;
  }

  .gnrImg {
    pointer-events: none;
    width: 80%;
    aspect-ratio: 3/2;
    object-fit: contain;
    padding: 20px 20px 0px 20px;
    // mix-blend-mode: normal;
  }
  .gnrTtl {
    width: 100%;
    color: $w;
    font-family: sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    padding-block: 10px;
    border-radius: 20px;
    background-color: $dG2;
    margin-top: 20px;
  }
}

.ebItm {
  cursor: pointer;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $GW;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding-bottom: 10px;
  .flip-card {
    padding-top: 20px;
    background-color: transparent;
    width: 80%;
    aspect-ratio: 1;
    object-fit: contain;
    perspective: 1000px;
    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
      .flip-card-front {
        position: absolute;
        width: 100%;
        -webkit-backface-visibility: hidden;
        /* Safari */
        backface-visibility: hidden;
        .ebImg {
          pointer-events: none;
          border-radius: 10px;
          width: 100%;
          aspect-ratio: 1;
          object-fit: fill;
        }
      }
      .flip-card-back {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $pnk;
        font-family: sans-serif;
        font-size: small;
        overflow: hidden;
        color: $w;
        transform: rotateY(180deg);
        -webkit-backface-visibility: hidden;
        /* Safari */
        backface-visibility: hidden;
      }
    }
  }
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  .ebBtn,
  .ebBtn2 {
    cursor: pointer;
    text-align: center;
    background-color: $dG2;
    color: $w;
    font-weight: 600;
    font-family: sans-serif;
    border-radius: 25px;
    padding: 10px;
    padding-inline: 20px;
    margin-block: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    transition: 600ms;
  }
  .ebBtn2 {
    background-color: $dGr;
  }
  .ebBtn:hover {
    box-shadow: none;
    background-color: $pnk;
  }
}

.mobEb {
  width: 180px;
  @include media(mobile, tabletPo) {
    width: 140px;
  }
  @include media(desktop, desktop-big, tabletLa) {
    width: 180px;
  }
}

.plrSuListD {
  box-shadow: 0 0 20px #00000050;
  width: 400px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: $w;
  margin: 20px;
  padding-bottom: 10px;

  .plrSubHead {
    border-radius: 20px;
    box-shadow: 0 4px 10px #00000050;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .plrSubImg {
      object-fit: contain;
      width: 20%;
      aspect-ratio: 1;
    }
    .plrSubTxt {
      width: 70%;
      text-align: center;
      font-weight: 500;
      font-size: large;
    }
  }
  .subList {
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    background-color: $w;
    border-radius: 20px;
    max-height: 400px;
    overflow-y: scroll;
    .subItem {
      display: flex;
      align-items: center;
      margin-block: 5px;
      border-radius: 10px;
      padding-inline: 10px;
      box-shadow: 0 0 4px $GW;
      justify-content: space-between;
      .subIcn {
        text-align: center;
        font-size: 20px;
        width: 20%;
      }
      .sub {
        width: 75%;
        text-align: left;
      }
    }
  }
}

.altDe {
  // Detail Evaluation Report Subject Box
  width: 30vw;
  @include media(mobile, tabletPo) {
    width: 90vw;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 30vw;
  }
  max-height: 44vh;
  .plrSubHead {
    .altDeImg {
      pointer-events: none;
      width: 20%;
      aspect-ratio: 1.5;
    }
  }
  .subList {
    .deSubItm {
      flex-direction: column;
      padding: 0px 20px 10px 20px;
      .deSub {
        text-align: center;
        font-size: large;
        font-weight: 600;
        margin-block: 10px;
      }
    }
  }
}

.st {
  box-shadow: 0 0 15px $gr;
  .tyHead {
    box-shadow: 0 3px 10px $gr;
  }
  .tyItm {
    background-color: $GW;
  }
  .tyIcn {
    color: $gr;
  }
}

.wk {
  box-shadow: 0 0 15px $pnk;
  .tyHead {
    box-shadow: 0 3px 10px $pnk;
  }
  .tyItm {
    background-color: $Wpnk;
  }
  .tyIcn {
    color: $pnk;
  }
}

.vidItm {
  pointer-events: none;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: $Wpnk;
  align-items: center;
  margin: 50px 20px;
  margin-top: 20px;
  border-radius: 10px;
  transition: transform 300ms ease 100ms;
  &:hover {
    transform: scale(1.1);
    .vidBtnDiv {
      max-height: 100px;
    }
  }

  .remIcn {
    display: flex;
    position: absolute;
    background-color: $w;
    justify-self: end;
    align-self: flex-end;
    margin: 5px;
    padding: 5px;
    border-radius: 200px;
    aspect-ratio: 1;
    color: $lG;
    box-shadow: 0 0 10px $gr;
    transition: 600ms;
  }
  .remIcn:hover {
    color: $w;
    background-color: $lG;
    box-shadow: none;
  }
  .playDiv {
    pointer-events: none;
    border-radius: 10px;
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    .vidPlay {
      font-size: 0px;
      opacity: 0.6;
      cursor: pointer;
      color: $lpnk;
      box-shadow: 0 0 20px $lpnk;
      border-radius: 100px;
      transition: 600ms;
    }
    .vidPlay:hover {
      opacity: 1;
      color: $pnk;
    }
  }
  .vidHead {
    text-align: center;
    font-weight: 500;
    margin-block: 20px;
    color: $pnk;
    letter-spacing: 0.5px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    @include media(mobile, tabletPo) {
      margin-block: 6px;
    }
    @include media(desktop, desktop-big, tabletLa) {
      margin-block: 10px;
    }
  }

  .vidBtnDiv {
    max-height: 0px;
    transition: 600ms;
    border-radius: 0 0 10px 10px;
    padding-inline: 5%;
    background-color: $w;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    overflow: hidden;
    .vidViews {
      text-align: center;
      font-size: 12px;
      color: $dGr;
      margin: 0px;
      font-weight: bold;
      .altView {
        font-size: 10px;
        font-weight: 500;
        margin: 0px;
      }
    }
    .vidBtn {
      cursor: pointer;
      font-size: small;
      background-color: $Wpnk;
      padding: 4px 8px;
      border-radius: 20px;
      color: $pnk;
      font-weight: 600;
      letter-spacing: 0.4px;
      transition: 600ms;
      box-shadow: 0 0 10px $pnk;
      .btnIcn {
        font-size: 24px;
      }
    }

    .vidBtn:hover {
      background-color: $pnk;
      color: $w;
      box-shadow: none;
    }
  }
}

.alt {
  pointer-events: initial;
  cursor: pointer;
  opacity: 1;
}

.pdfMain {
  padding-inline: 5%;
  width: 70%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include media(mobile, tabletPo) {
    padding-inline: 0%;
    width: 100%;
    height: unset;
  }

  @include media(desktop, desktop-big, tabletLa) {
    padding-inline: 5%;
    width: 70%;
    height: 85vh;
  }
}

.pdfPage {
  .pdf {
    align-self: center;
    // justify-self: center;
  }
}

.pdfBtnDiv {
  width: 70vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @include media(mobile, tabletPo) {
    width: 100vw;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 70vw;
  }
  .pdfBtn,
  .pdfTxt {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px $dGr;
    margin: 20px;
    margin-bottom: 0px;
    background-color: $dGr;
    color: $w;
    transition: 600ms;
    font-size: large;
    @include media(mobile, tabletPo) {
      font-size: unset;
      padding: 8px 10px;
      margin: 5px;
      margin-bottom: 0px;
    }

    @include media(desktop, desktop-big, tabletLa) {
      font-size: large;
      padding: 10px 20px;
      margin: 20px;
      margin-bottom: 0px;
    }
  }
  .pdfBtn:hover {
    box-shadow: none;
  }
  .pdfTxt {
    box-shadow: none;
    background-color: unset;
    color: unset;
  }
}

.ebPdfMain {
  width: 90vw;
  height: 90vh;
}
