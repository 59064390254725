@import "./config.scss";

.homeMain {
  font-family: sans-serif;
  user-select: none;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  .Search {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 10px;
    @include media(mobile, tabletPo) {
      width: 400px;
    }

    @include media(desktop, desktop-big, tabletLa) {
      width: 800px;
    }

    .searchInput {
      width: 60%;
      border: 1px solid #a5a1a1;
      padding: 10px;
      margin-block: 5px;
      border-radius: 5px;
    }

    .searchInput:focus {
      outline: none !important;
      border-color: $lG;
      box-shadow: 0 0 5px $lG;
    }

    .searchIcn {
      background-color: $lG;
      color: $w;
      padding: 9px;
      font-size: 18px;
      border-radius: 4px;
    }
  }
  ///////////////////////////// Start of Land Dashboard /////////////////////////////////////
  .landMain {
    .hCon {
      background-repeat: no-repeat;
      width: 100vw;
      height: 350px;
      display: flex;
      align-items: center;
      @include media(desktop, desktop-big, tabletLa) {
        background-image: url("../Assets/ui/hero_bulb.gif");
        background-position: 70%;
        background-size: contain;
      }

      .tsfDiv {
        background-color: $w;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        @include media(desktop, desktop-big, tabletLa) {
          margin-left: 6vw;
          width: unset;
        }

        .tsfTitle {
          color: $pnk;
          font-size: 40px;
          text-transform: capitalize;
          font-weight: 800;
          font-family: serif;
          text-align: center;
        }

        .btnCon {
          width: 300px;
          display: flex;
          justify-content: space-evenly;

          .login {
            background-color: $lG;
            border: 0px;
            padding: 15px;
            color: $w;
            font-size: 20px;
            font-family: sans-serif;
            border-radius: 20px;
            font-weight: 600;
            cursor: pointer;
            background: linear-gradient(to right, $pnk 50%, $lG 50%);
            background-size: 200% 100%;
            background-position: right;
            transition: all 0.5s ease-out;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
          }

          .login:hover {
            background-position: left;
            background-color: $pnk;
            box-shadow: 0px 8px 0px;
          }
        }
      }
    }

    .caroDiv {
      display: flex;
      overflow: hidden;
      margin-block: 20px;
      border-radius: 200px;
    }

    .provDiv {
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: center;
      width: 100vw;
      aspect-ratio: 1.25;
      background-image: url("../Assets/ui/bgg2.png");
      box-shadow: 0 0 8px 8px white inset;
      background-position: "center";
      background-size: cover;
      background-repeat: no-repeat;

      .provHead {
        padding: 0px 0 10px;
        position: relative;
        font-size: 30px;
        margin-block: 40px;
      }

      .provHead::before {
        content: "";
        position: absolute;
        border-radius: 20px;
        bottom: 14px;
        width: 230px;
        height: 6px;
        background: #39e3e0;
        z-index: -1;
      }

      .gridDiv {
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .statDiv {
        margin-top: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .statCon {
          margin-block: 20px;
          border-right: 1px solid $pnk;
          width: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          @include media(desktop, desktop-big, tabletLa) {
            padding-inline: 80px;
            width: unset;
          }
          .statIcon {
            color: $lG;
            font-size: 80px;
          }
          .statTitle {
            font-size: 24px;
            color: $pnk;
            font-weight: 600;
            margin-block: 10px;
          }
        }
      }
    }

    .PopCourseDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      .popTitle {
        padding: 0px 0 10px;
        position: relative;
        font-size: 30px;
        margin-block: 40px;
      }
      .popTitle::before {
        content: "";
        position: absolute;
        border-radius: 20px;
        bottom: 14px;
        width: 285px;
        height: 6px;
        background: #39e3e0;
        z-index: -1;
      }
      .popDesc {
        text-align: center;
      }
      .playImg {
        pointer-events: none;
        box-shadow: 0 0 8px 8px #fcfcfc;
        object-fit: contain;
        width: 700px;
        @include media(mobile, tabletPo) {
          width: 90%;
        }
        @include media(desktop, desktop-big, tabletLa) {
          width: 50%;
        }
      }
    }

    .rvwDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .rvwTitle {
        padding: 10px 0px;
        position: relative;
        font-size: 30px;
        margin-block: 40px;
        @include media(mobile, tabletPo) {
          font-size: 26px;
        }
        @include media(desktop, desktop-big, tabletLa) {
          font-size: 30px;
        }
      }
      .rvwTitle::before {
        content: "";
        position: absolute;
        border-radius: 20px;
        bottom: 14px;
        width: 400px;
        height: 6px;
        background: #39e3e0;
        z-index: -1;
        @include media(mobile, tabletPo) {
          width: 400px;
          height: 5px;
        }

        @include media(desktop, desktop-big, tabletLa) {
          width: 440px;
          height: 6px;
        }
      }
    }
  }
  ///////////////////////////// End of Land Dashboard ///////////////////////////////////////

  ////////////////////////////// Start of About Us //////////////////////////////////////////
  .aboutMain {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 90%;
    @include media(mobile, tabletPo) {
      width: 94%;
    }
    @include media(desktop, desktop-big, tabletLa) {
      width: 90%;
    }
    .abtImg {
      pointer-events: none;
      width: 70%;
      object-fit: contain;
      align-self: center;
      @include media(mobile, tabletPo) {
        width: 90%;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: 50%;
        margin-top: 2%;
      }
    }
    .abtDiv2 {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 2%;
      .lrnTxt {
        width: 50%;
        font-size: 20px;
        @include media(mobile, tabletPo) {
          width: 100%;
          text-align: center;
        }
        @include media(desktop, desktop-big, tabletLa) {
          width: 50%;
          text-align: unset;
        }
        .lrnHgh {
          color: $pnk;
          font-weight: 800;
        }
      }
      .lrnImg {
        pointer-events: none;
        @include media(mobile, tabletPo) {
          width: 400px;
          text-align: center;
        }

        @include media(desktop, desktop-big, tabletLa) {
          width: 500px;
        }
      }
    }
    .abtComp {
      .abtTitle {
        padding: 0px 0 10px;
        position: relative;
        font-size: 30px;

        @include media(mobile, tabletPo) {
          text-align: center;
        }
        @include media(desktop, desktop-big, tabletLa) {
          text-align: unset;
          margin-top: 5%;
        }
      }
      .abtTitle::before {
        content: "";
        position: absolute;
        border-radius: 20px;
        bottom: 14px;
        width: 275px;
        height: 6px;
        background: #39e3e0;
        z-index: -1;
      }
    }
    .teamDiv {
      margin-block: 30px;
      .teamTitle {
        padding: 0px 0 10px;
        position: relative;
        font-size: 30px;
        @include media(mobile, tabletPo) {
          text-align: center;
        }
        @include media(desktop, desktop-big, tabletLa) {
          text-align: unset;
        }
      }
      .teamTitle::before {
        content: "";
        position: absolute;
        border-radius: 20px;
        bottom: 14px;
        width: 140px;
        height: 6px;
        background: #39e3e0;
        z-index: -1;
      }
    }
    .msgTeamDiv {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .msgDiv {
        width: 70%;
        @include media(mobile, tabletPo) {
          width: unset;
          text-align: justify;
        }
        @include media(desktop, desktop-big, tabletLa) {
          width: 70%;
        }
        .msgTitle {
          padding: 0px 0 10px;
          position: relative;
          font-size: 30px;
          margin-block: 40px;
          @include media(mobile, tabletPo) {
            text-align: center;
          }
          @include media(desktop, desktop-big, tabletLa) {
            text-align: unset;
          }
        }
        .msgTitle::before {
          content: "";
          position: absolute;
          border-radius: 20px;
          bottom: 14px;
          width: 345px;
          height: 6px;
          background: #39e3e0;
          z-index: -1;
        }
      }
      .tmImg {
        pointer-events: none;
        @include media(mobile, tabletPo) {
          width: 400px;
        }
        @include media(desktop, desktop-big, tabletLa) {
          width: 30%;
        }
      }
    }
  }
  ////////////////////////////// End of About Us ////////////////////////////////////////////

  ////////////////////////////// Start of Contact Us ////////////////////////////////////////
  .contactMain {
    display: flex;
    flex-direction: column;
    align-self: center;
    .contextDiv {
      background-color: #f0f7f7;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100vw;
      display: flex;
      .contxt {
        padding-inline: 10px;
        width: 30%;
        @include media(mobile, tabletPo) {
          width: 90%;
          text-align: center;
        }
        @include media(tabletLa, desktop, desktop-big) {
          width: 30%;
          text-align: unset;
        }
        .contxt1 {
          color: $pnk;
          font-family: sans-serif;
          font-size: 30px;
          font-weight: 600;
        }
        .contxt2 {
          color: $dG2;
          font-family: sans-serif;
          font-size: 28px;
          font-weight: 600;
        }
        .contxt3 {
          color: $lG;
          font-family: sans-serif;
          font-size: 26px;
          font-weight: 600;
        }
      }
      .contxtImg {
        pointer-events: none;
        @include media(mobile, tabletPo) {
          width: 80%;
        }
        @include media(tabletLa, desktop, desktop-big) {
          width: 500px;
        }
      }
    }
    .cntctDiv {
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      width: 100vw;
      display: flex;
      .formDiv {
        width: 500px;
        margin: 40px;
        display: flex;
        flex-direction: column;
        .formInput {
          border: 1px solid #ced4da;
          padding: 10px;
          margin-block: 5px;
          border-radius: 5px;
        }
        .formInput:focus {
          outline: none !important;
          border-color: $lG;
          box-shadow: 0 0 5px $lG;
        }
        .formCaptcha {
          align-self: center;
          width: 150px;
          border: 1px solid #ced4da;
          padding: 10px;
          margin-block: 5px;
          border-radius: 5px;
        }
        .captcha {
          text-decoration: line-through underline overline dashed;
          text-decoration-color: #ced4da;
          letter-spacing: 6px;
          color: $w;
          background-color: rgba(0, 0, 0, 0.15);
          padding: 7px;
          margin-inline: 5px;
          border: 1px solid #ced4da;
          text-align: center;
          margin-block: 5px;
          border-radius: 5px;
        }
        .sec {
          text-decoration: none;
          letter-spacing: normal;
          background-color: $gr;
          border-radius: 12px;
          border: none;
        }
        .rfCaptcha {
          cursor: pointer;
          margin-inline: 5px;
          background-color: $pnk;
          padding: 10px;
          color: $w;
          border-radius: 10px;
          box-shadow: 0 0 10px $pnk;
          transition: 600ms;
        }
        .rfCaptcha:hover {
          box-shadow: none;
        }
        .submit {
          display: flex;
          align-items: center;
          margin-block: 10px;
          .subTxt {
            width: 80%;
            font-size: 20px;
            color: $pnk;
            font-weight: 600;
            font-family: sans-serif;
          }
          .submitBtn {
            background-color: $lG;
            color: $w;
            border: 0;
            align-self: center;
            padding-block: 10px;
            border-radius: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            width: 20%;
          }
        }
      }
      .reachDiv {
        margin: 46px 10px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 10px;
        .reachTxt {
          padding: 0px 0 10px;
          position: relative;
          font-size: 26px;
          text-align: center;
        }

        .reachTxt::before {
          content: "";
          position: absolute;
          border-radius: 20px;
          bottom: 14px;
          width: 150px;
          height: 5px;
          background: #39e3e0;
          z-index: -1;
        }
        .reach {
          padding-inline: 10px;
          font-size: 18px;
          font-weight: 500;
          font-family: sans-serif;
          .reachIcn {
            font-size: 24px;
          }
        }
      }
    }
    .mapouter {
      align-self: center;
      margin-block: 20px;
      width: 50%;
      .gmap_canvas {
        overflow: hidden;
        width: 100%;
        height: 400px;
        .gmap_iframe {
          height: 400px;
          border: 0px;
        }
      }
    }
  }
  ////////////////////////////// End of Contact Us //////////////////////////////////////////

  ////////////////////////////// Start of Courses ////////////////////////////////////////
  .courseMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    .xplorDiv {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      @include media(mobile, tabletPo) {
        justify-content: center;
        flex-wrap: wrap-reverse;
        width: 500px;
      }
      @include media(desktop, desktop-big, tabletLa) {
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 100%;
      }
      .xplorTxt {
        font-size: 46px;
        padding-right: 5%;
        color: $pnk;
        font-family: sans-serif;
        font-weight: 900;
        @include media(mobile, tabletPo) {
          padding-right: 5%;
          padding-left: 5%;
          font-size: x-large;
        }

        @include media(desktop, desktop-big, tabletLa) {
          padding-right: 5%;
          padding-left: unset;
          font-size: xx-large;
        }
      }
      .xplor {
        width: 35%;
        display: flex;
        justify-content: flex-end;
        @include media(mobile, tabletPo) {
          width: unset;
          display: unset;
          justify-content: unset;
        }

        @include media(desktop, desktop-big, tabletLa) {
          width: 33%;
          padding-right: 2%;
          display: flex;
          justify-content: flex-end;
        }
        .xplorImg {
          pointer-events: none;
          align-self: center;
          object-fit: contain;
          width: 250px;
        }
      }
    }
    .prevDiv {
      width: 100%;
      display: flex;
      flex-wrap: wrap-reverse;
      justify-content: center;
      align-items: flex-end;
      .prevCatDiv {
        overflow: hidden;
        width: 350px;
        @include media(mobile, tabletPo) {
          width: 98%;
        }
        @include media(desktop, desktop-big, tabletLa) {
          width: 20vw;
        }
      }
      .prevCrslDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 800px;
        @include media(mobile, tabletPo) {
          width: 98%;
        }
        @include media(tabletLa) {
          width: 600px;
        }
        @include media(desktop, desktop-big) {
          width: 55vw;
        }
      }
    }
  }
  ////////////////////////////// End of Courses //////////////////////////////////////////

  ////////////////////////////// Start of Exam Updates //////////////////////////////////////
  .exmUpdtsMain {
    margin-top: -2vh;
    display: flex;
    flex-direction: column;
    .xmUpDiv {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      @include media(mobile, tabletPo) {
        justify-content: center;
        flex-wrap: wrap-reverse;
      }
      @include media(desktop, desktop-big, tabletLa) {
        justify-content: flex-end;
        flex-wrap: wrap;
      }
      .xmUpTxt {
        font-size: 46px;
        color: $pnk;
        font-family: sans-serif;
        font-weight: 900;
        @include media(mobile, tabletPo) {
          padding-right: 4%;
          padding-left: 4%;
          font-size: 32px;
        }
        @include media(desktop, desktop-big, tabletLa) {
          padding-right: 2%;
          padding-left: unset;
          font-size: 46px;
        }
      }
      .xmUp {
        width: 35%;
        display: flex;
        justify-content: flex-end;
        @include media(mobile, tabletPo) {
          width: unset;
          display: unset;
          justify-content: unset;
        }
        @include media(desktop, desktop-big, tabletLa) {
          width: 33%;
          padding-right: 2%;
          display: flex;
          justify-content: flex-end;
        }
        .xmUpImg {
          pointer-events: none;
          align-self: center;
          object-fit: contain;
          width: 250px;
        }
      }
    }
    .xmListCom {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      @include media(mobile, tabletPo) {
        padding-inline: 2%;
        justify-content: center;
      }
      @include media(desktop, desktop-big, tabletLa) {
        padding-inline: 21%;
        justify-content: space-between;
      }
      .xmlatest {
        background-color: $pnk;
        color: $w;
        padding: 15px;
        font-size: larger;
        font-family: sans-serif;
        font-weight: 600;
        border-radius: 20px;
        @include media(mobile, tabletPo) {
          padding-inline: 2%;
          justify-content: center;
        }
      }

      .modSrch {
        @include media(mobile, tabletPo) {
          width: 60%;
        }
        @include media(desktop, desktop-big, tabletLa) {
          width: 80%;
        }
      }
    }
    .xmCatLstD {
      display: flex;
      flex-wrap: wrap;
      align-self: center;
      margin-inline: 2%;
      justify-content: space-around;
      background-color: white;
      border-radius: 10px;
      margin-top: 20px;
      @include media(mobile, tabletPo) {
        width: 95%;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: 60%;
      }
      .xmCat {
        cursor: pointer;
        background-color: $lG;
        padding: 10px;
        border-radius: 20px;
        color: $w;
        box-shadow: 0 0 3px $gr;
        transition: 500ms ease;
        @include media(mobile, tabletPo) {
          margin-block: 8px;
        }
        @include media(desktop, desktop-big, tabletLa) {
          margin-block: unset;
        }
      }
      .xmCatAct {
        cursor: unset;
        background-color: $dG2;
      }
    }
  }
  ////////////////////////////// End of Exam Updates ////////////////////////////////////////

  ////////////////////////////// Start of EBooks ////////////////////////////////////////////
  .ebookMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    .ebwlcmDiv {
      width: 80%;
      background-image: url("../Assets/ui/title-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 10px;
      box-shadow: 0 0 15px $dG2;
      padding-inline: 10px;
      margin-block: 20px;
      .ebwlcmTxt {
        text-align: center;
        color: $w;
        font-family: sans-serif;
      }
    }
  }
  ////////////////////////////// End of EBooks ////////////////////////////////////////////
}
