@import "./config.scss";

.caMain {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  .caHdDiv {
    width: 100%;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap-reverse;
    padding-left: 10%;
    @include media(mobile, tabletPo) {
      display: none;
    }
    @include media(desktop, desktop-big, tabletLa) {
      display: flex;
    }
    .caTrnslt {
      display: flex;
      align-items: center;
    }
    .caNmDiv {
      padding-right: 10%;
      display: flex;
      justify-content: center;
      @include media(mobile, tabletPo) {
        width: 100%;
        padding-right: 0%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 80%;
        padding-right: 10%;
      }
    }
  }

  .mobNaHd {
    width: 100%;
    display: flex;
    background-color: #f6e7ea;
    margin-bottom: 10px;
    align-items: center;
    padding-block: 4px;
    @include media(mobile, tabletPo) {
      display: flex;
    }
    @include media(desktop, desktop-big, tabletLa) {
      display: none;
    }
    .mobLngD {
      margin-left: -20px;
      transform: scale(0.6);
      font-size: large;
      .Mui-checked + .MuiSwitch-track {
        background-color: $lG !important;
      }
    }
    .mobCatName {
      margin: 0px;
      color: $dGr;
      font-style: italic;
      font-weight: 600;
      font-family: serif;
      font-size: large;
      width: 30%;
      text-align: center;
      padding-right: 20%;
    }
  }

  .caName {
    text-align: center;
    margin-block: 20px;
    background-color: $w;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: x-large;
    font-weight: 600;
    color: $dG2;
    box-shadow: 0 0 10px $lpnk;
    margin-bottom: 10px;

    @include media(mobile, tabletPo) {
      font-size: medium;
    }

    @include media(desktop, desktop-big, tabletLa) {
      font-size: x-large;
    }
  }
  .caDiv {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.MuiInputBase-input,
.MuiOutlinedInput-input,
.Mui-readOnly,
.MuiInputBase-readOnly,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 35px !important;
  font-size: 14px;
}
