@import "./config.scss";

.footer {
  user-select: none;
  width: 100%;
  color: $w;
  padding-bottom: 20px;
  margin-top: 50px;
  @include media(mobile, tabletPo, tabletLa) {
    background-color: $dG2;
    background-image: unset;
  }
  @include media(desktop, desktop-big) {
    width: auto;
    background-size: cover;
    background-color: unset;
    background-image: url("../Assets/ui/footer_bg2.png");
    background-repeat: no-repeat;
    padding-top: 18vh;
  }

  .footerCon {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-evenly;
    .footerDiv-a {
      padding-inline: 0.5vw;
      @include media(mobile, tabletPo) {
        width: 230px;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: 400px;
      }

      .tncD {
        @include media(mobile, tabletPo) {
          display: none;
        }

        @include media(desktop, desktop-big, tabletLa) {
          display: unset;
        }
      }
    }
    .footerDiv-a2 {
      @include media(mobile, tabletPo) {
        width: 250px;
        font-weight: 450;
      }

      @include media(desktop, desktop-big, tabletLa) {
        display: none;
      }
    }
    .footerDiv-b {
      padding-inline: 0.5vw;

      @include media(mobile, tabletPo) {
        width: 150px;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 200px;
        margin-top: 1%;
      }
    }
    .footerDiv-c {
      padding-inline: 1vw;
      @include media(mobile, tabletPo) {
        width: 400px;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: 600px;
      }
    }

    .footerItem {
      cursor: pointer;
      color: $w;
      @include media(mobile, tabletPo) {
        font-weight: 300;
      }
      @include media(desktop, desktop-big, tabletLa) {
        font-weight: 400;
      }
    }
    .footerItem:hover {
      color: $pnk;
    }
    .tsf {
      color: #3ef7f0;
      @include media(mobile, tabletPo) {
        font-size: 15px;
      }
      @include media(desktop, desktop-big, tabletLa) {
        font-weight: 700;
      }
    }
    .dms {
      font-size: 16px;
      color: yellow;
    }

    .mbD {
      @include media(mobile, tabletPo) {
        font-weight: 300;
      }
      @include media(desktop, desktop-big, tabletLa) {
        font-weight: 400;
      }
    }

    .newsletter {
      background-color: $pnk;
      padding: 15px;
      display: flex;
      flex-direction: column;

      .nwsTxt {
        color: $b;
        font-weight: 400;
        margin-bottom: 10px;
      }

      .nwsDiv {
        display: flex;

        .nwsEmail {
          padding-inline: 5px;
          height: 30px;
          width: 85%;
          border: 0px;
        }
        .nwsSend {
          display: flex;
          flex-direction: column;
          width: 15%;
          justify-content: center;
          height: 32px;
          background-color: $lG;
        }
      }
    }
    .footerAbout {
      font-size: 16px;
    }
    .icnDiv {
      display: flex;
      justify-content: center;
      .soIcn {
        cursor: pointer;
        font-size: 20px;
        margin-inline: 10px;
        background-color: $w;
        aspect-ratio: 1;
        color: $dG2;
        padding: 10px;
        border-radius: 25px;
      }
    }
  }
}

.copyright {
  user-select: none;
  background-color: $lG;
  text-align: center;
  color: $w;
  width: 100%;
  padding-block: 5px;
}
