@import url("https: //cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css");

@import "./config.scss";

.crsCoDiv {
  display: flex;
  flex-direction: column;
  .catTxt {
    text-align: center;
    font-size: 24px;
    font-family: sans-serif;
    font-weight: bold;
    margin-block: 20px;
  }
  .crsList {
    height: 60vh;
    overflow: scroll;
    padding-inline: 5px;
    display: flex;
    flex-direction: column;
    .crsItem {
      cursor: pointer;
      width: 90%;
      border-radius: 10px;
      align-self: center;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      padding-inline: 10px;
      padding-block: 14px;
      margin-block: 8px;

      .crsIcn {
        width: 10%;
        font-size: 20px;
      }
      .crsIcn {
        cursor: pointer;
        width: 10%;
        font-size: 20px;
      }
      .crsIcn:hover {
        color: $pnk;
      }
      .crsTtl {
        cursor: pointer;
        padding-inline: 4%;
        width: 80%;
        font-size: 20px;
        font-family: sans-serif;
      }
      .crsTtl:hover {
        color: $pnk;
      }
    }
  }
}

.xmList {
  margin-block: 20px;
  display: flex;
  flex-wrap: wrap;
  padding-inline: 15%;
  justify-content: space-between;
  align-items: center;
  @include media(mobile, tabletPo) {
    padding-inline: 2%;
    justify-content: center;
  }
  @include media(desktop, desktop-big, tabletLa) {
    padding-inline: 15%;
    justify-content: space-between;
  }
  .sno {
    width: 4%;
    text-align: center;
    border-right: 1px solid $w;
    padding: 10px;
  }
  .xmNm {
    text-align: center;
    border-right: 1px solid $w;
    padding: 10px;
    @include media(mobile, tabletPo) {
      width: 30%;
      padding: 5px;
      overflow: hidden;
    }

    @include media(desktop, desktop-big, tabletLa) {
      width: 30%;
      padding: 10px;
      overflow: unset;
    }
  }
  .elgblt {
    text-align: center;
    border-right: 1px solid $w;
    padding: 10px;
    @include media(mobile, tabletPo) {
      width: 40%;
      padding: 5px;
      overflow: hidden;
    }
    @include media(desktop, desktop-big, tabletLa) {
      width: 25%;
      padding: 10px;
      overflow: unset;
    }
    .rdmr {
      color: $pnk;
    }
  }
  .lstdt {
    width: 10%;
    text-align: center;
    border-right: 1px solid $w;
    padding: 10px;
    @include media(mobile, tabletPo) {
      width: 22%;
      padding: 5px;
    }

    @include media(desktop, desktop-big, tabletLa) {
      width: 10%;
      padding: 10px;
    }
  }
  .exp {
    color: $pnk;
  }
  .vld {
    color: $dGr;
    font-weight: 600;
  }
  .vw {
    width: 7%;
    text-align: center;
    padding: 10px;
  }
  .vwEye {
    cursor: pointer;
    width: 7%;
    font-size: 16px;
    text-align: center;
    padding: 10px;
    .eyeIcn {
      color: $pnk;
      background-color: $w;
      padding: 5px;
      border-radius: 100px;
      box-shadow: 0 0 10px $pnk;
      transition: 600ms;
    }
    .eyeIcn:hover {
      box-shadow: none;
      background-color: $pnk;
      color: $w;
    }
  }
  .xmDRes {
    @include media(mobile, tabletPo) {
      display: none;
    }
    @include media(desktop, desktop-big, tabletLa) {
      display: unset;
    }
  }
  .xmMRes {
    @include media(mobile, tabletPo) {
      display: unset;
    }
    @include media(desktop, desktop-big, tabletLa) {
      display: none;
    }
  }

  .listHead {
    width: 100%;
    display: flex;
    background-color: $dGr;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: $w;
    font-size: medium;
    font-weight: 600;
    box-shadow: 0 0 8px $dGr;
    margin-block: 6px;
    padding-right: 1%;
    @include media(mobile, tabletPo) {
      font-size: small;
      font-weight: 400;
    }
    @include media(desktop, desktop-big, tabletLa) {
      font-size: medium;
      font-weight: 600;
    }
  }
  .mRes {
    @include media(mobile, tabletPo) {
      display: none;
    }
    @include media(desktop, desktop-big, tabletLa) {
      display: unset;
    }
  }
  .listData {
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid $dGr;
    border-top: 0px;
    height: 56vh;
    overflow-y: scroll;
    overflow-x: hidden;
    .listItem {
      overflow: hidden;
      width: 100%;
      display: flex;
      background-color: $GW;
      border: 1px solid $w;
      font-size: small;
      align-items: center;
      @include media(mobile, tabletPo) {
        font-size: smaller;
      }

      @include media(desktop, desktop-big, tabletLa) {
        font-size: small;
      }
    }
  }
}

.ebsrchDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .ebSearchDiv {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-block: 10px;
    .ebSearch {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .searchInput {
      justify-self: center;
      font-size: 16px;
      width: 80%;
      border: 1px solid #ced4da;
      padding: 10px;
      margin-block: 5px;
      border-radius: 5px;
      @include media(mobile, tabletPo) {
        width: 60%;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: 80%;
      }
    }
    .searchInput:focus {
      outline: none !important;
      border-color: $lG;
      box-shadow: 0 0 5px $lG;
    }
    .searchIcn {
      vertical-align: middle;
      background-color: $lG;
      color: $w;
      padding: 10px;
      font-size: 18px;
      border-radius: 4px;
    }
    .ebRsltDiv {
      background-color: $w;
      z-index: 1;
      position: absolute;
      align-self: flex-start;
      max-height: 300px;
      overflow-y: scroll;
      width: 320px;
      top: 165px;
      margin-left: 15px;
      border-top: 0px;
      border-radius: 10px;
      padding: 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      @include media(mobile, tabletPo) {
        top: 235px;
      }
      @include media(desktop, desktop-big, tabletLa) {
        top: 165px;
      }
      .ebItem {
        margin-block: 5px;
        padding-block: 5px;
        border-bottom: 1px solid #ced4da;
        .ebKey {
          background-color: $GW;
        }
      }
    }
  }
  .ebSubDiv {
    min-width: 200px;
    max-width: 400px;
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid $lG !important;
      box-shadow: 0 0 5px $lG;
    }
    .ebslct {
      height: 40px;
      padding-top: 10px;
    }
  }
}

.MquesLstM {
  margin-block: 40px;
  display: flex;
  flex-direction: column;
  width: 80%;
  // border: 1px solid $b;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  @include media(mobile, tabletPo) {
    width: 94%;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 80%;
  }
  .LstHead {
    border-radius: 20px;
    background-color: $lG;
    box-shadow: 0 0 10px $lG;
    color: $w;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 600;
    padding-inline: 5px;
  }
  .lstItmDiv {
    display: flex;
    flex-direction: column;
    padding-inline: 5px;
    max-height: 400px;
    overflow-y: scroll;
    .lstItm {
      border-radius: 20px;
      margin-block: 5px;
      color: $b;
      box-shadow: 0 0 10px $GW;
      background-color: $GW;
      display: flex;
      align-items: center;
    }
  }
  .qno,
  .qSec,
  .qStatus,
  .qDif,
  .qexpl {
    margin-inline: 1%;
    font-size: large;
    text-align: center;
    @include media(mobile, tabletPo) {
      font-size: small;
    }

    @include media(desktop, desktop-big, tabletLa) {
      font-size: large;
    }
  }

  .qno {
    width: 10%;
  }

  .qSec {
    width: 25%;
  }

  .qStatus {
    width: 20%;
    text-transform: capitalize;
  }

  .qDif {
    width: 20%;
  }

  .qexpl {
    width: 25%;
  }
  .qexp_alt {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .stC {
    font-size: large;
    border: 2px solid $gr;
    color: $gr;
    border-radius: 100px;
    padding: 8px;
    aspect-ratio: 1;
    @include media(mobile, tabletPo) {
      font-size: unset;
      padding: 4px;
    }

    @include media(desktop, desktop-big, tabletLa) {
      font-size: large;
      padding: 8px;
    }
  }

  .wtC {
    font-size: large;
    border: 2px solid $pnk;
    color: $pnk;
    border-radius: 100px;
    padding: 8px;
    aspect-ratio: 1;
    text-align: center;
    @include media(mobile, tabletPo) {
      font-size: unset;
      padding: 4px;
    }

    @include media(desktop, desktop-big, tabletLa) {
      font-size: large;
      padding: 8px;
    }
  }
  .qexplBtn {
    cursor: pointer;
    margin-block: 0;
    text-align: center;
    color: $lpnk;
    border: 1px solid $lpnk;
    box-shadow: 0 0 10px $lpnk;
    background-color: $Wpnk;
    border-radius: 20px;
    align-self: center;
    padding: 5px 10px;
    transition: 600ms;
  }
  .qexplBtn:hover {
    box-shadow: none;
    color: $Wpnk;
    background-color: $lpnk;
  }
}

.prMoLstMain {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: space-around;
  .lstItm {
    width: 380px;
    padding: 10px;
    display: flex;
    background-color: $GW;
    border-radius: 20px;
    border: 1px solid $gr;
    box-shadow: 0 0 5px $gr;
    margin: 10px;
    justify-content: space-between;
    align-items: center;
    @include media(mobile, tabletPo) {
      width: 280px;
      font-size: small;
    }
    @include media(desktop, desktop-big, tabletLa) {
      width: 380px;
      font-size: unset;
    }
    .lstTxtDiv {
      display: flex;
      flex-direction: column;
      .lstTxt {
        margin: 5px;
        color: $dGr;
      }
    }
    .lstBtnDiv {
      display: flex;
      flex-direction: column;
      .lstBtn1,
      .lstBtn2 {
        cursor: pointer;
        margin-block: 10px;
        padding: 10px 15px;
        background-color: $dGr;
        color: $w;
        border-radius: 20px;
        box-shadow: 0 0 10px $dGr;
        transition: 600ms;
      }
      .lstBtn1 {
        background-color: $lG;
        box-shadow: 0 0 10px $lG;
      }
      .lstBtn1:hover,
      .lstBtn2:hover {
        box-shadow: none;
      }
    }
  }
}

.tpcLstDiv {
  max-height: 84vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 94%;
  padding-inline: 2%;
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  border-radius: 50px;
  justify-content: space-between;
  @include media(mobile, tabletPo) {
    max-height: 96vh;
  }
  @include media(desktop, desktop-big, tabletLa) {
    max-height: 84vh;
  }
  .itmDiv {
    width: 45%;
    justify-content: space-between;
    display: flex;
    padding: 10px;
    background-color: $GW;
    border-radius: 100px;
    box-shadow: 0 0 10px $GW;
    margin-block: 10px;
    align-items: center;
    @include media(mobile, tabletPo) {
      width: 96%;
    }

    @include media(desktop, desktop-big, tabletLa) {
      width: 45%;
    }
    .sNo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8%;
      .txt {
        margin: 0;
        vertical-align: middle;
        text-align: center;
        width: 50px;
        padding-block: 14px;
        border-radius: 100px;
        font-size: large;
        color: $w;
        background-color: $dG2;
        align-self: center;
        @include media(mobile, tabletPo) {
          font-size: small;
          padding-block: 8px;
          width: 30px;
        }
        @include media(desktop, desktop-big, tabletLa) {
          font-size: large;
          padding-block: 10px;
          width: 40px;
        }
      }
    }
    .topicName {
      margin: 0;
      width: 48%;
      padding-inline: 1%;
      @include media(mobile, tabletPo) {
        width: 48%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 48%;
      }
    }
    .btnDiv {
      width: 30%;
      justify-content: space-around;
      display: flex;
      @include media(mobile, tabletPo) {
        width: 40%;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: 50%;
      }
      .btn {
        margin: 0px;
        background-color: $Wpnk;
        padding: 5px 10px;
        border-radius: 20px;
        font-size: large;
        cursor: pointer;
        box-shadow: 0 0 10px $pnk;
        color: $pnk;
        transition: 600ms;
        border: 1px solid $pnk;
        @include media(mobile, tabletPo) {
          padding: 4px 5px;
          font-size: small;
          box-shadow: 0 0 3px $pnk;
        }

        @include media(desktop, desktop-big, tabletLa) {
          padding: 6px 12px;
          font-size: large;
        }
      }
      .btn:hover {
        box-shadow: none;
        color: $w;
        background-color: $pnk;
      }
      .btn-d,
      .btn-d:hover {
        background-color: $Wpnk;
        box-shadow: none;
        color: $pnk;
      }
    }
  }
}

.rdTpcDiv,
.naCatDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 1%;
  @include media(mobile, tabletPo) {
    width: 98%;
    margin-inline: 1%;
  }
  @include media(desktop, desktop-big, tabletLa) {
    width: 18%;
    margin-inline: unset;
  }
  .tpcTxt {
    padding-block: 15px;
    margin: 0px;
    width: 100%;
    background-color: $gr;
    color: $w;
    border-radius: 20px;
    text-align: center;
    font-size: larger;
    font-weight: 600;
    box-shadow: 0 0 10px $lG;
    @include media(mobile, tabletPo) {
      font-size: medium;
      padding-block: 8px;
    }

    @include media(desktop, desktop-big, tabletLa) {
      font-size: larger;
      padding-block: 15px;
    }
  }
  .rdList,
  .caLst {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $GW;
    border-radius: 10px;
    width: 100%;
    max-height: 78vh;
    overflow: hidden scroll;
    @include media(mobile, tabletPo) {
      flex-wrap: wrap;
    }
    @include media(desktop, desktop-big, tabletLa) {
      flex-wrap: unset;
    }
    .rdItm,
    .actItm,
    .naItm,
    .actNaItm {
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      width: 90%;
      background-color: $w;
      margin-block: 6px;
      border-radius: 10px;
      box-shadow: 0 0 4px $gr;
      padding-block: 10px;
      transition: 600ms;
      @include media(mobile, tabletPo) {
        font-size: small;
        padding-inline: 5px;
        padding-block: 5px;
        width: unset;
        margin-inline: 6px;
      }

      @include media(desktop, desktop-big, tabletLa) {
        font-size: unset;
        padding-inline: unset;
        padding-block: 10px;
        width: 90%;
        margin-inline: unset;
      }
      .rdTpc,
      .naCat {
        width: 90%;
        text-align: center;
        margin: 0px;
        @include media(mobile, tabletPo) {
          padding-inline: 10px;
        }

        @include media(desktop, desktop-big, tabletLa) {
          padding-inline: unset;
        }
      }
      .naBmIcn {
        cursor: pointer;
        color: $GW;
        background-color: $lG;
        //box-shadow: 0 0 10px $gr;
        padding: 5px;
        border-radius: 100px;
        transition: 600ms;
        font-size: x-small;
        align-self: flex-end;
        justify-self: flex-end;
        margin-inline: 5px;
      }
    }
    .rdItm:hover {
      background-color: $lpnk;
      box-shadow: 0 0 10px $lpnk;
      color: $w;
    }

    .actItm,
    .actNaItm {
      background-color: $pnk;
      box-shadow: none;
      color: $w;
    }

    .naBmIcn:hover {
      box-shadow: none;
    }
    .tqBtm {
      margin-block: 15px;
      background-color: $lG;
      color: $w;
      width: 60%;
      font-weight: 600;
      font-size: large;
      @include media(mobile, tabletPo) {
        font-size: unset;
        width: 90%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        font-size: large;
        width: 60%;
      }
    }
    .tqBtm:hover {
      background-color: $dG2;
      box-shadow: 0 0 10px $gr;
    }
  }
}

.naCatDiv {
  @include media(mobile, tabletPo) {
    width: 96%;
    flex-wrap: wrap;
    flex-direction: column;
  }
  @include media(desktop, desktop-big, tabletLa) {
    width: 16%;
    flex-direction: column;
  }
  .caLst {
    @include media(mobile, tabletPo) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    @include media(desktop, desktop-big, tabletLa) {
      flex-direction: column;
      flex-wrap: initial;
      justify-content: initial;
    }
    .naItm,
    .actNaItm {
      width: 90%;
      @include media(mobile, tabletPo) {
        font-size: medium;
        padding-inline: unset;
        padding-block: 5px;
        margin: 6%;
        margin-block: 8px;
      }
      @include media(desktop, desktop-big, tabletLa) {
        font-size: unset;
        padding-inline: unset;
        padding-block: 10px;
        margin: initial;
        margin-block: 10px;
      }
      .naCat {
        width: 100%;
      }
    }
  }
}

.mCatRes {
  @include media(mobile, tabletPo) {
    display: none;
  }
  @include media(desktop, desktop-big, tabletLa) {
    display: flex;
  }
}

.curRptM {
  margin-block: 40px;
  display: flex;
  flex-direction: column;
  width: 90%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  @include media(mobile, tabletPo) {
    width: 94%;
  }
  @include media(desktop, desktop-big, tabletLa) {
    width: 90%;
  }
  .LstHead {
    border-radius: 20px;
    background-color: $lG;
    box-shadow: 0 0 10px $lG;
    color: $w;
    display: flex;
    justify-content: space-evenly;
    font-weight: 600;
    padding: 6px 10px;
  }
  .lstItmDiv {
    display: flex;
    flex-direction: column;
    padding-inline: 5px;
    max-height: 400px;
    overflow-y: scroll;
    .lstItm {
      border-radius: 20px;
      margin-block: 5px;
      color: $b;
      box-shadow: 0 0 10px $GW;
      background-color: $GW;
      display: flex;
      align-items: center;
    }
  }
  .clm {
    width: 20%;
    margin-inline: 1%;
    font-size: large;
    text-align: center;
    margin-block: 10px;
    @include media(mobile, tabletPo) {
      font-size: small;
    }

    @include media(desktop, desktop-big, tabletLa) {
      font-size: larger;
    }
  }
}

.mvpSrchDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  flex-direction: column;
  margin-block: 10px;
  .mvpSrch {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ced4da;
    border-radius: 20px;
    padding-inline: 5px;
    .mvpInput {
      justify-self: center;
      font-size: 16px;
      width: 80%;
      border: 0px;
      padding: 10px;
      @include media(mobile, tabletPo) {
        width: 60%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 80%;
      }
    }
    .mvpInput:focus {
      outline: none !important;
    }
    .srchBtn {
      cursor: pointer;
      background-color: $lG;
      box-shadow: 0 0 10px $lG;
      border-radius: 100px;
      color: $w;
      padding: 10px;
      font-size: 16px;
      margin-block: 4px;
      transition: 600ms;
    }
    .srchBtn:hover {
      box-shadow: none;
    }
  }
  .mvpResDiv {
    background-color: $w;
    z-index: 1;
    position: absolute;
    align-self: flex-start;
    max-height: 300px;
    overflow-y: scroll;
    width: 500px;
    top: 165px;
    margin-left: 15px;
    border-top: 0px;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

    @include media(mobile, tabletPo) {
      top: 235px;
    }

    @include media(desktop, desktop-big, tabletLa) {
      top: 165px;
    }

    .mvpItm {
      margin-block: 5px;
      padding-block: 5px;
      border-bottom: 1px solid #ced4da;
      .mvpKey {
        padding: 1px;
        padding-block: 4px;
        border-radius: 10px;
        background-color: #f389aa60;
      }
    }
  }
}

.ebGrdMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .Search {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 10px;
    .searchInput {
      width: 70%;
      border: 1px solid #ced4da;
      padding: 10px;
      font-size: 20px;
      margin-block: 5px;
      border-radius: 20px;
    }
    .searchInput:focus {
      outline: none !important;
      border: 1px solid $lpnk;
      box-shadow: 0 0 5px $lpnk;
    }
    .searchIcn {
      background-color: $lG;
      color: $w;
      padding: 16px;
      font-size: 20px;
      border-radius: 4px;
    }
  }
  .ebList {
    max-height: 81vh;
    overflow: hidden scroll;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .ldMrDiv {
      width: 100%;
      display: flex;
      justify-content: center;
      .ldMrBtn {
        cursor: pointer;
        background-color: $lpnk;
        color: $w;
        padding: 10px 20px;
        border-radius: 20px;
        box-shadow: 0 0 10px $pnk;
        margin-block: 10px;
        transition: 600ms;
        font-weight: 600;
        letter-spacing: 0.6px;
      }
      .ldMrBtn:hover {
        background-color: $pnk;
        box-shadow: none;
      }
    }
  }
}

.pdLstMain {
  max-height: 80vh;
  overflow: hidden scroll;
  padding-inline: 2%;
  width: 96%;
  display: flex;
  flex-wrap: wrap;
  @include media(mobile, tabletPo) {
    max-height: 74vh;
  }
  @include media(desktop, desktop-big, tabletLa) {
    max-height: 84vh;
  }
  .pdItm {
    margin-block: 10px;
    width: 44%;
    margin-inline: 2%;
    padding-inline: 1%;
    display: flex;
    align-items: center;
    background-color: $GW;
    border-radius: 10px;
    box-shadow: 0 0 10px $lpnk;
    @include media(mobile, tabletPo) {
      width: 96%;
    }
    @include media(desktop, desktop-big, tabletLa) {
      width: 44%;
    }
    .pdIcn {
      cursor: pointer;
      font-size: xx-large;
      color: $pnk;
      box-shadow: 0 0 10px $dG2;
      border-radius: 100px;
      margin-right: 2%;
      transition: 600ms;
      @include media(mobile, tabletPo) {
        font-size: x-large;
      }

      @include media(desktop, desktop-big, tabletLa) {
        font-size: xx-large;
      }
    }

    .pdTtl {
      width: 70%;
      margin-inline: 1%;
      font-weight: 600;
      color: $dGr;
      letter-spacing: 0.4px;
      @include media(mobile, tabletPo) {
        width: 55%;
        font-size: small;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 70%;
        font-size: unset;
      }
    }
    .pdShDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 21%;
      @include media(mobile, tabletPo) {
        width: 36%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 21%;
      }
      .pdDt {
        font-size: small;
      }
      .pdSh {
        cursor: pointer;
        background-color: $dG2;
        color: $w;
        padding: 8px;
        border-radius: 100px;
        aspect-ratio: 1;
        box-shadow: 0 0 10px $gr;
        font-size: small;
        transition: 600ms;
      }
    }
    .pdIcn:hover,
    .pdSh:hover {
      box-shadow: none;
    }
  }
}

.caLstDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-height: 83vh;
  overflow: hidden scroll;
  @include media(mobile, tabletPo) {
    max-height: 74vh;
  }
  @include media(desktop, desktop-big, tabletLa) {
    max-height: 83vh;
  }
  .caItm {
    display: flex;
    width: 46%;
    justify-content: space-around;
    background-color: $GW;
    margin-block: 10px;
    border-radius: 20px;
    box-shadow: 0 0 10px $gr;
    align-items: center;
    @include media(mobile, tabletPo) {
      width: 94%;
    }

    @include media(desktop, desktop-big, tabletLa) {
      width: 46%;
    }
    .caIndDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $dG2;
      color: $w;
      width: 40px;
      aspect-ratio: 1;
      border-radius: 100px;
      .caInd {
        margin: 0px;
      }
    }
    .caHeading {
      width: 76%;
      @include media(mobile, tabletPo) {
        width: 55%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 70%;
      }
    }
    .caBmIcn {
      margin-right: 5px;
      cursor: pointer;
      color: $GW;
      background-color: $lG;
      box-shadow: 0 0 10px $gr;
      padding: 10px;
      border-radius: 100px;
      transition: 600ms;
    }
    .caBmIcn:hover {
      box-shadow: none;
    }
    .caRdBtn {
      cursor: pointer;
      background-color: $pnk;
      color: $w;
      padding: 8px 16px;
      margin: 0px;
      border-radius: 20px;
      box-shadow: 0 0 10px $lpnk;
      transition: 600ms;
    }
    .caRdBtn:hover {
      box-shadow: none;
    }
  }
}

.maLst {
  @include media(mobile, tabletPo) {
    max-height: 57vh;
  }

  @include media(desktop, desktop-big, tabletLa) {
    max-height: 74vh;
  }
}

.maCatDiv {
  width: 70%;
  display: flex;
  justify-content: space-around;
  background-color: $Wpnk;
  border-radius: 20px;
  box-shadow: 0 0 6px $lpnk;
  flex-wrap: wrap;
  margin-block: 10px;
  @include media(mobile, tabletPo) {
    width: 94%;
  }
  @include media(desktop, desktop-big, tabletLa) {
    width: 70%;
  }
  .maCat {
    margin-block: 12px;
    cursor: pointer;
    background-color: $lG;
    color: $w;
    padding: 6px 15px;
    border-radius: 20px;
    box-shadow: 0 0 6px $dGr;
    font-weight: 600;
    font-size: large;
    transition: 600ms;
    @include media(mobile, tabletPo) {
      font-size: unset;
      font-weight: unset;
      padding: 6px 10px;
      margin-block: 8px;
    }

    @include media(desktop, desktop-big, tabletLa) {
      font-size: large;
      font-weight: 600;
      padding: 6px 15px;
      margin-block: 12px;
    }
  }
  .maCat:hover {
    background-color: $lpnk;
    box-shadow: 0 0 10px $pnk;
  }
  .actMa,
  .actMa:hover {
    background-color: $pnk;
    box-shadow: none;
  }
}

.rmLstMain {
  padding-inline: 2%;
  width: 96%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-height: 82vh;
  overflow: hidden scroll;
  align-items: flex-start;
  .rmLstItm {
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $Wpnk;
    box-shadow: 0 0 10px $gr;
    margin-block: 10px;
    border-radius: 20px;
    padding-inline: 2%;
    transition: 600ms;
    @include media(mobile, tabletPo) {
      width: 92%;
    }

    @include media(desktop, desktop-big, tabletLa) {
      width: 40%;
    }
    .rmIndDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6%;
      background-color: $pnk;
      aspect-ratio: 1;
      border-radius: 100px;
      color: $w;
      font-weight: 600;
      .rmInd {
        margin: 0px;
      }
    }
    .rmInfoDiv {
      width: 70%;
      // border: 1px solid $b;
      .rmCrs {
        font-size: medium;
        font-weight: 600;
        letter-spacing: 0.4px;
        margin-bottom: 0px;
        @include media(mobile, tabletPo) {
          margin-left: 2%;
        }
      }
      .rmProg {
        font-size: small;
        margin-top: 5px;
      }
    }
    .bmInfo {
      width: 50%;
    }
    .rmBtnDiv {
      display: flex;
      width: 25%;
      justify-content: space-between;
    }
    .rmBtn,
    .rdBtn {
      text-align: center;
      color: $w;
      background-color: $dG2;
      border-radius: 20px;
      padding: 8px 14px;
      box-shadow: 0 0 10px $gr;
      transition: 600ms;
      cursor: pointer;
    }
    .rdBtn {
      background-color: $gr;
    }
    .rmBtn:hover,
    .rdBtn:hover {
      background-color: $dGr;
      box-shadow: none;
    }
  }
  .rmLstItm:hover {
    transform: scale(1.1);
  }
}
