@import "./config.scss";

.profileMain {
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
  .usrActDiv {
    width: 45%;
    @include media(mobile, tabletPo) {
      width: 94%;
    }
    @include media(desktop, desktop-big, tabletLa) {
      width: 40%;
    }
  }
}
