@import "./config.scss";

.navbar {
  user-select: none;
  font-family: sans-serif;
  position: fixed;
  top: 0;
  z-index: 1001;
  width: 100vw;
  padding: 4px;
  background-color: $w;
  box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.2);
  @include media(mobile, tabletPo) {
    padding-block: 12px;
  }
  @include media(desktop, desktop-big, tabletLa) {
    padding-block: 5px;
  }
  .mobileMaiGayab{
    @include media(mobile, tabletPo) {
    display: none;
  }
  @include media(desktop, desktop-big, tabletLa) {
    display: unset;
  }
  }
  color: #555;
  .imgDiv {
    flex-wrap: wrap;
    width: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    @include media(mobile, tabletPo) {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
    }

    @include media(desktop, desktop-big, tabletLa) {
      flex-wrap: unset;
      flex-direction: row;
      justify-content: space-around;
    }
    .brLogo {
      cursor: pointer;
      width: 100%;
      object-fit: contain;
      filter: contrast(110%);

      @include media(mobile, tabletPo) {
        width: unset;
        height: 60px;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 30%;
        height: 75px;
      }
    }
    .subTxt {
      cursor: pointer;
      margin: 0px 20px;
      background-color: $lpnk;
      border: 1px solid $lpnk;
      color: $Wpnk;
      box-shadow: 0 0 10px $pnk;
      border-radius: 10px;
      font-weight: 600;
      transition: 600ms;
      font-family: sans-serif;
      transition: 600ms;
      animation: mymove 2s infinite;
      letter-spacing: 0.6px;
      @include media(mobile, tabletPo, tabletLa) {
        padding: 6px 8px;
        font-size: medium;
        font-weight: 500;
        letter-spacing: unset;
      }

      @include media(desktop, desktop-big) {
        padding: 8px 14px;
        font-size: large;
        font-weight: 600;
        letter-spacing: 0.6px;
      }
    }
    @keyframes mymove {
      0% {
        box-shadow: 0 0 0px $pnk;
      }
      50% {
        box-shadow: 10px 10px 20px $pnk;
      }
      100% {
        box-shadow: 0 0 0px $pnk;
      }
    }
    .subTxt:hover {
      animation: none;
      box-shadow: none;
    }
  }
  .sIcn {
    cursor: pointer;
    padding-block: 10px;

    margin-top: 5px;
    @include media(mobile, tabletPo, tabletLa) {
      font-size: large;
    }
    @include media(desktop, desktop-big) {
      font-size: x-large;
    }
  }

  .dRes {
    @include media(mobile, tabletPo, tabletLa) {
      display: none;
    }
    @include media(desktop, desktop-big) {
      display: unset;
    }
  }

  .mRes {
    @include media(mobile, tabletPo, tabletLa) {
      display: unset;
      width: 10%;
      display: flex;
      align-self: center;
    }
    @include media(desktop, desktop-big) {
      display: none;
      width: unset;
    }
  }

  .navCon {
    flex-wrap: wrap;
    padding-inline: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 16px;
    @include media(mobile, tabletPo) {
      width: 50%;
    }
    @include media(desktop, desktop-big, tabletLa) {
      width: 80%;
    }

    .crsDiv {
      border: 1px solid #00000040;
      border-radius: 10px;
      display: flex;
      align-items: center;
      @include media(mobile, tabletPo) {
        width: 160px;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: 240px;
      }

      .crsIcn {
        cursor: pointer;
        aspect-ratio: 1;
        padding: 8px;
        background-color: $gr;
        color: $w;
        margin-inline: 4px;
        border-radius: 200px;
        box-shadow: 0 0 5px $gr;
        transition: 500ms;
        @include media(mobile, tabletPo) {
          font-size: 12px;
          margin-top: 4px;
        }
        @include media(desktop, desktop-big, tabletLa) {
          font-size: unset;
          margin-top: unset;
        }
      }
      .crsIcn:hover {
        background-color: $pnk;
        box-shadow: unset;
      }
      .navCrsCon {
        border: 0px;
        border-radius: 10px;

        @include media(mobile, tabletPo) {
          width: 124px;
          font-size: 12px;
        }
        @include media(desktop, desktop-big, tabletLa) {
          width: 204px;
          font-size: unset;
        }
      }
      .navCrsCon:hover {
        border: 0px;
      }
      .navCrsOpt:hover {
        background-color: $GW;
        color: $b;
      }
    }
    .crsDiv:hover,
    .crsDiv:focus {
      border: 1px solid $gr;
      box-shadow: 0 0 5px $gr;
    }
    .navItem {
      cursor: pointer;
      color: #555;
      padding: 5px;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      margin: 4px;
    }
    .navItem:hover {
      color: $pnk;
    }
    .navItem-active {
      margin: 4px;
      cursor: pointer;
      padding: 5px;
      font-size: 20px;
      font-weight: 600;
      color: #00cccc;
      text-decoration: none;
    }
    .login {
      border-width: 0px;
      background-color: $w;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
    }
    .login:hover {
      color: $pnk;
    }
    .register {
      border: 2px solid $gr;
      color: $gr;
      background: linear-gradient(to right, $pnk 50%, white 50%);
      background-size: 200% 100%;
      background-position: right bottom;
      transition: all 0.5s ease-out;
      border-radius: 10px;
      font-size: 18px;
      cursor: pointer;
      padding-inline: 10px;
      padding-block: 8px;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      
    }
    .register:hover {
      box-shadow: 0px 8px 0px;
      background-position: left bottom;
      border: 2px solid $pnk;
      background-color: $pnk;
      color: $w;
    }
    .notIcn {
      display: flex;
      align-items: flex-start;
      .notBadge {
        margin-left: -16px;
        background-color: $pnk;
        padding: 3px;
        padding-inline: 5px;
        aspect-ratio: 1;
        font-size: x-small;
        border-radius: 100px;
        // position: absolute;
        color: $w;
      }
    }

    .notDRes {
      @include media(mobile, tabletPo, tabletLa) {
        display: none;
      }
      @include media(desktop, desktop-big) {
        display: unset;
      }
    }
  }

  .resNv {
    @include media(mobile, tabletPo, tabletLa) {
      display: none;
    }
    @include media(desktop, desktop-big) {
      display: unset;
    }
  }
}
.notPanel {
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  max-height: 40vh;
  overflow: hidden scroll;
  z-index: 1001;
  position: fixed;
  background-color: $w;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 10px $gr;
  right: 10px;
  @include media(mobile, tabletPo, tabletLa) {
    width: 95%;
  }
  @include media(desktop, desktop-big) {
    width: 25%;
  }
  .notItm,
  .notUr {
    display: flex;
    flex-direction: column;
    margin-inline: 2.5% 0%;
    padding-inline: 1%;
    margin-block: 4px;
    background-color: $GW;
    border-radius: 10px;
    .notMsg {
      font-size: small;
      margin: 5px;
      overflow-wrap: break-word;
    }
    .notDt {
      margin: 5px;
      font-size: x-small;
      text-align: end;
    }
    .not {
      position: absolute;
      right: 1px;
      width: 8px;
      aspect-ratio: 1;
      background-color: $pnk;
      border-radius: 100px;
    }
  }
  .notUr {
    background-color: $Wpnk;
  }
}

.devHead {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 4px;
  background-color: $pnk;
  color: white;
  font-weight: bold;
  letter-spacing: 0.4px;
  margin-block: -4px 1vh;
}

.notMRes {
  @include media(mobile, tabletPo, tabletLa) {
    width: 10%;
    display: flex;
    align-self: center;
  }
  @include media(desktop, desktop-big) {
    display: none;
    width: unset;
  }
}

.crsRes {
  @include media(mobile, tabletPo, tabletLa) {
    width: 0% !important;
  }
  @include media(desktop, desktop-big) {
    width: 75% !important;
  }
}

.imgRes {
  @include media(mobile, tabletPo, tabletLa) {
    width: 75% !important;
    flex-direction: row !important;
    justify-content: space-around !important;
  }
  @include media(desktop, desktop-big) {
    width: 25% !important;
  }
}
