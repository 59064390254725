@import "./config.scss";

.ebGnrMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  .ebGnrDiv {
    width: 100%;
    display: flex;
    @include media(mobile, tabletPo) {
      flex-direction: column;
    }
    @include media(desktop, desktop-big, tabletLa) {
      flex-direction: row;
    }
    .mobNaHd {
      width: 100%;
      display: flex;
      background-color: #f6e7ea;
      margin-bottom: 10px;
      align-items: center;
      padding-block: 4px;
      @include media(mobile, tabletPo) {
        display: flex;
      }
      @include media(desktop, desktop-big, tabletLa) {
        display: none;
      }
      .mobLngD {
        margin-left: -20px;
        transform: scale(0.6);
        font-size: large;
        .Mui-checked + .MuiSwitch-track {
          background-color: $lG !important;
        }
      }
      .mobCatName {
        margin: 0px;
        color: $dGr;
        font-style: italic;
        font-weight: 600;
        font-family: serif;
        font-size: large;
        width: 30%;
        text-align: center;
        padding-right: 20%;
      }
      .moEbNm {
        width: 40%;
        padding-left: 30%;
      }
    }
  }
}
