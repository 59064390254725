@import "./config.scss";

.mtMain {
  margin-top: 100px;
  .mockHead {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    .MCrsName {
      text-align: center;
      padding: 15px 20px;
      background-color: $Wpnk;
      box-shadow: 0 0 5px $gr;
      border-radius: 25px;
      font-weight: 600;
      font-size: larger;
      color: $dG2;
    }
    .mockHdRowDiv {
      justify-content: space-around;
      flex-wrap: wrap;
      width: 90%;
      display: flex;
      align-items: center;
      padding-inline: 10px;
      .mockAttemptD {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        border: 2px solid $pnk;
        border-radius: 25px;
        box-shadow: 0 0 20px $pnk;
        @include media(mobile, tabletPo) {
          width: 94%;
        }
        @include media(desktop, desktop-big, tabletLa) {
          width: 30%;
        }
        .mockAtmptTxt {
          font-size: large;
          font-weight: 600;
        }
        .attemptBtn {
          cursor: pointer;
          padding: 15px 30px;
          color: $dGr;
          background-color: $GW;
          border-radius: 20px;
          transition: 600ms;
          border: 2px solid $dGr;
          box-shadow: 0 0 15px $dGr;
          font-size: large;
          font-weight: 600;
        }
        .attemptBtn:hover {
          background-color: $dGr;
          color: $GW;
          box-shadow: none;
        }
      }
      .mockAtmptImg {
        width: 30%;
        @include media(mobile, tabletPo) {
          width: 94%;
        }

        @include media(desktop, desktop-big, tabletLa) {
          width: 30%;
        }
      }
    }
  }
}

.prevMockDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  .prevMockTxt {
    padding: 15px 40px;
    border: 2px solid $gr;
    box-shadow: 0 0 10px $gr;
    color: $pnk;
    border-radius: 20px;
    font-size: larger;
    font-weight: 600;
  }
  .noMockTxt {
    background-color: $Wpnk;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: larger;
    box-shadow: 0 0 20px $Wpnk;
  }
}

.MoTrmsD {
  width: 80%;
  align-self: center;
  display: flex;
  flex-direction: column;
  @include media(mobile, tabletPo) {
    width: 96%;
  }
  @include media(desktop, desktop-big, tabletLa) {
    width: 80%;
  }
  .moHdTxt {
    align-self: center;
    text-align: center;
    color: $pnk;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    border-radius: 20px;
  }
  .moTrmsImg {
    align-self: center;
    width: 300px;
    object-fit: contain;
  }
  .moTrmsTxt {
    text-align: center;
    font-weight: 600;
    font-size: larger;
    margin-bottom: 30px;
  }
  .trmsTxt {
    .Mopt {
      font-size: smaller;
      padding: 6px 10px;
      aspect-ratio: 1;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border-radius: 100px;
    }
    .nv {
      background-color: #d5d5d5;
    }
    .nAns {
      color: $w;
      background-color: #fc5107;
    }
    .ans {
      color: $w;
      background-color: #7ac021;
    }
    .nar {
      color: $w;
      background-color: #8b65bf;
    }
    .ar {
      background-color: #9999ff;
      color: $w;
    }
    .trmRed {
      color: #fc5107;
      font-weight: 600;
    }
  }
  .MoBtnDiv {
    display: flex;
    justify-content: center;
  }
  .MoNxtBtn {
    align-self: center;
    cursor: pointer;
    margin-block: 30px;
    background-color: $dGr;
    color: $w;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px $dGr;
    transition: 600ms;
  }
  .MoNxtBtn:hover {
    box-shadow: none;
  }
}

.mockExam {
  margin-top: 110px;
  .mockNav {
    z-index: 2;
    background-color: $w;
    position: fixed;
    top: 0px;
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    @include media(mobile, tabletPo) {
      width: 95vw;
    }

    @include media(desktop, desktop-big, tabletLa) {
      width: 100vw;
    }
    .navImg {
      width: 20%;
      object-fit: contain;
      height: 60px;
    }
    .navCrs {
      width: 40%;
      word-wrap: break-word;
      height: unset;
      text-align: center;
      padding-left: 5%;  
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden;
      @include media(mobile, tabletPo) {
        height: 40px;
        font-size: large;
      }
      @include media(desktop, desktop-big, tabletLa) {
        height: unset;
        font-size: larger;
      }
    }
    .moDrawerIcn {
      color: $w;
      background-color: $pnk;
      padding: 5px;
      margin: 5px;
      border-radius: 100px;
      box-shadow: 0 0 10px $pnk;
      transition: 600ms;
      font-size: 16px;
      display: none;
      @include media(mobile, tabletPo) {
        display: unset;
        
      }

      @include media(desktop, desktop-big, tabletLa) {
        display: none;
      }
    }
    .moDrawerIcn:hover {
      box-shadow: none;
    }
    .td {
      height: initial;
      padding-left: 0%;
      // font-size: large;
      @include media(mobile, tabletPo) {
        width: 25%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 40%;
      }
    }
  }
  .mockDiv {
    justify-content: space-evenly;
    width: 100%;
    padding-inline: 10px;
    display: flex;
    .sideDiv {
      width: 29%;
      @include media(mobile, tabletPo) {
        display: none;
      }
      @include media(desktop, desktop-big, tabletLa) {
        display: unset;
      }
    }
  }
}

.moRpMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .RpTxt {
    background-color: $Wpnk;
    color: $pnk;
    border-radius: 20px;
    box-shadow: 0 0 10px $Wpnk;
    padding: 15px 30px;
    font-size: larger;
    text-align: center;
    font-weight: 600;
  }
  .moRpDiv {
    width: 100%;
    // border:1px solid $b;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding-inline: 10px;
    .scRkD {
      width: 40%;
      display: flex;
      flex-direction: column;
      @include media(mobile, tabletPo) {
        width: 90%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 40%;
      }
      .scRk {
        display: flex;
        justify-content: space-evenly;
        .scRkTxt {
          background-color: $gr;
          box-shadow: 0 0 10px $gr;
          border-radius: 20px;
          border-radius: 20px;
          padding: 20px 0px;
          color: $w;
          text-align: center;
          margin: 10px;
          width: 200px;
          font-size: larger;
        }
      }
      .co {
        text-align: center;
        margin-block: 20px 10px;
        font-size: large;
        font-weight: 600;
      }
      .nc {
        color: $pnk;
      }
      .c {
        color: $gr;
      }
      .rpCo,
      .rpThnt {
        text-align: center;
        font-weight: 600;
        font-size: large;
      }
      .rpThnt {
        font-size: xx-large;
        margin-block: 5px;
      }
    }
    .rpMrkDiv {
      // border: 1px solid $b;
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include media(mobile, tabletPo) {
        width: 90%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 40%;
      }
      .rpMrkImg {
        width: 40%;
        margin-bottom: 20px;
        object-fit: contain;
        @include media(mobile, tabletPo) {
          width: 50%;
        }

        @include media(desktop, desktop-big, tabletLa) {
          width: 40%;
        }
      }
      .rpMrkLst {
        margin-block: 8px;
        border-radius: 100px;
        box-shadow: 0 0 10px $GW;
        background-color: $GW;
        width: 80%;
        display: flex;
        padding-inline: 10%;
        justify-content: space-between;
        align-items: center;
        font-size: larger;
        @include media(mobile, tabletPo) {
          font-size: unset;
        }
        @include media(desktop, desktop-big, tabletLa) {
          font-size: larger;
        }
        .rpMrkTxt {
          margin: 0;
          display: flex;
          align-items: center;
          .rpCrIcn {
            color: $gr;
            border: 3px solid $gr;
            border-radius: 100px;
            padding: 4px 5px;
          }
          .rpWrIcn {
            font-size: 28px;
            color: $pnk;
          }
          .rpUnIcn {
            color: #6adff7;
            font-size: 28px;
          }
          .rpIcn {
            color: $lpnk;
            font-size: 28px;
          }
        }
      }
      .alt {
        background-color: $Wpnk;
      }
    }
    .rpDpaBtnD {
      margin-block: 20px;
      display: flex;
      width: 35%;
      justify-content: center;
      @include media(mobile, tabletPo) {
        width: 100%;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: 35%;
      }
      .rpDpaBtn {
        cursor: pointer;
        padding: 20px 40px;
        background-color: $GW;
        border: 1px solid $dGr;
        color: $dGr;
        font-size: larger;
        font-weight: 600;
        border-radius: 20px;
        box-shadow: 0 0 20px $dGr;
        transition: 600ms;
        @include media(mobile, tabletPo) {
          font-size: medium;
          padding: 15px 30px;
        }

        @include media(desktop, desktop-big, tabletLa) {
          font-size: large;
          padding: 20px 40px;
        }
      }
      .rpDpaBtn:hover {
        box-shadow: none;
        background-color: $dGr;
        color: $w;
      }
    }
    .rpDpaImgD {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      @include media(mobile, tabletPo) {
        width: 90%;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: 40%;
      }
      .rpDpaImg {
        align-self: center;
        width: 60%;
        object-fit: contain;
      }
    }
    .rpAcBtnDiv {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      .rpAcBtn {
        cursor: pointer;
        margin-inline: 10px;
        background-color: $dGr;
        box-shadow: 0 0 10px $dGr;
        border-radius: 20px;
        padding: 15px 30px;
        color: $w;
        font-size: large;
        transition: 600ms;
      }
      .rpAcBtn:hover {
        box-shadow: none;
      }
    }
  }
}

.moDpaMain {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .RpTxt {
    background-color: $Wpnk;
    color: $pnk;
    border-radius: 20px;
    box-shadow: 0 0 10px $Wpnk;
    padding: 15px 30px;
    font-size: larger;
    text-align: center;
    font-weight: 600;
  }
  .dpaModeSwitchD {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    @include media(mobile, tabletPo) {
      width: 90%;
    }
    @include media(desktop, desktop-big, tabletLa) {
      width: 50%;
    }
    .dpaModBtn {
      cursor: pointer;
      background-color: $lG;
      box-shadow: 0 0 10px $lG;
      padding: 10px 20px;
      font-size: larger;
      font-weight: 600;
      border-radius: 20px;
      color: $w;
      transition: 600ms;
      @include media(mobile, tabletPo) {
        font-size: unset;
        padding: 10px 10px;
      }

      @include media(desktop, desktop-big, tabletLa) {
        font-size: larger;
        padding: 10px 20px;
      }
    }
    .dpaModBtn:hover {
      background-color: $dG2;
      box-shadow: 0 0 10px $dG2;
    }
    .dmBtnAct {
      background-color: $dG2;
      box-shadow: none;
    }
  }
  .dpaScChDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .dpaScDiv {
      margin: 10px;
      background-color: $pnk;
      box-shadow: 0 0 5px $pnk;
      border-radius: 20px;
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      text-align: center;
      .dpaSc {
        display: flex;
        flex-direction: column;
        @include media(mobile, tabletPo) {
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
        }

        @include media(desktop, desktop-big, tabletLa) {
          flex-direction: column;
          width: unset;
          justify-content: unset;
        }
        .scTxt {
          margin: 10px;
          font-size: larger;
          font-weight: 600;
          color: $Wpnk;
          @include media(mobile, tabletPo) {
            font-size: unset;
          }
          @include media(desktop, desktop-big, tabletLa) {
            font-size: larger;
          }
        }
      }
    }

    .dpaChDiv {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      .pieDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        @include media(mobile, tabletPo) {
          width: 80%;
        }

        @include media(desktop, desktop-big, tabletLa) {
          width: 25%;
        }
        .chTxt {
          font-size: large;
          font-weight: 600;
        }
      }
    }
  }

  .dpaMsTxt {
    margin-top: 40px;
    font-size: large;
    background-color: $GW;
    padding: 10px 20px;
    color: $dGr;
    border-radius: 10px;
    box-shadow: 0 0 5px $GW;
  }
  .dpaTbl {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    .tblHd,
    .tblItm {
      width: 100%;
      display: flex;
      background-color: $lG;
      box-shadow: 0 0 5px $lG;
      margin-bottom: 5px;
      border-radius: 20px;
      .tblHdTxt,
      .tblItmTxt {
        color: $w;
        width: 50%;
        text-align: center;
        font-size: larger;
        font-weight: 600;
      }
    }
    .tblItm {
      background-color: $w;
      box-shadow: none;
      .tblItmTxt {
        color: $b;
        border-left: 1px solid $b;
        margin: 0;
        padding: 20px;
      }
    }
  }
  .saBtn {
    cursor: pointer;
    margin-top: 50px;
    background-color: $dGr;
    color: $GW;
    border: 1px solid $dGr;
    border-radius: 20px;
    box-shadow: 0 0 10px $dGr;
    transition: 600ms;
    padding: 10px 20px;
    font-size: large;
    font-weight: 600;
  }
  .saBtn:hover {
    box-shadow: none;
  }
  .OaHddiv {
    margin-block: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .OaHdTxt {
      font-size: larger;
      margin-inline: 20px;
      font-weight: 600;
    }
  }
}

.mockSaMain {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .RpTxt {
    background-color: $Wpnk;
    color: $pnk;
    border-radius: 20px;
    box-shadow: 0 0 10px $Wpnk;
    padding: 15px 30px;
    font-size: larger;
    text-align: center;
    font-weight: 600;
  }
  .OaHddiv {
    margin-block: 10px 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .OaHdTxt {
      font-size: larger;
      margin-inline: 20px;
      font-weight: 600;
    }
  }
  .saDiv {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .saSecDiv {
      width: 96%;
      padding-inline: 2%;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      background-color: $GW;
      box-shadow: 0 0 10px $GW;
      border-radius: 20px;
      .saSecTxt {
        margin: 10px;
        background-color: $lG;
        padding: 10px 20px;
        font-size: large;
        border-radius: 100px;
        box-shadow: 0 0 20px $lG;
        color: $w;
        transition: 600ms;
        @include media(mobile, tabletPo) {
          margin: 10px 5px;
          padding: 8px 12px;
          font-size: unset;
        }

        @include media(desktop, desktop-big, tabletLa) {
          margin: 15px 10px;
          padding: 10px 20px;
          font-size: large;
        }
      }
      .saSecTxt:hover {
        background-color: $gr;
      }
      .secAt {
        background-color: $dG2;
        box-shadow: none;
      }
    }
    .saScDiv {
      width: 100%;
      margin-block: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      margin-block: 20px;
      .rpMrkLst {
        margin: 10px;
        border-radius: 100px;
        box-shadow: 0 0 10px $Wpnk;
        background-color: $Wpnk;
        display: flex;
        width: 18%;
        padding-inline: 2%;
        justify-content: space-between;
        align-items: center;
        font-size: larger;
        @include media(mobile, tabletPo) {
          font-size: unset;
          width: 90%;
        }
        @include media(desktop, desktop-big, tabletLa) {
          font-size: larger;
          width: 18%;
        }

        .rpMrkTxt {
          margin: 0;
          display: flex;
          align-items: center;

          .rpCrIcn {
            color: $gr;
            border: 3px solid $gr;
            border-radius: 100px;
            padding: 4px 5px;
          }

          .rpWrIcn {
            font-size: 28px;
            color: $pnk;
          }

          .rpUnIcn {
            color: #6adff7;
            font-size: 28px;
          }

          .rpAcIcn {
            color: $dG2;
            font-size: 28px;
          }

          .rpIcn {
            color: $lpnk;
            font-size: 28px;
          }
        }
      }
    }
    .mabtnDiv {
      display: flex;
      width: 50%;
      justify-content: space-evenly;
      @include media(mobile, tabletPo) {
        width: 90%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 50%;
      }
      .maBtn {
        cursor: pointer;
        background-color: $dGr;
        color: $w;
        font-size: large;
        padding: 10px 20px;
        border-radius: 20px;
        box-shadow: 0 0 10px $dGr;
        transition: 600ms;
        @include media(mobile, tabletPo) {
          font-size: unset;
        }
        @include media(desktop, desktop-big, tabletLa) {
          font-size: large;
        }
      }
      .maBtn:hover {
        box-shadow: none;
      }
    }
  }
}

.moTaMain {
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .RpTxt {
    background-color: $Wpnk;
    color: $pnk;
    border-radius: 20px;
    box-shadow: 0 0 10px $Wpnk;
    padding: 15px 30px;
    font-size: larger;
    text-align: center;
    font-weight: 600;
  }
  .taDiv {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tpLvlDiv {
      width: 96%;
      padding-inline: 2%;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      background-color: $GW;
      box-shadow: 0 0 10px $GW;
      border-radius: 20px;
      .tpLvl {
        margin: 10px;
        background-color: $lG;
        padding: 10px 20px;
        font-size: large;
        border-radius: 100px;
        box-shadow: 0 0 20px $lG;
        color: $w;
        transition: 600ms;
      }
      @include media(mobile, tabletPo) {
        margin: 10px 5px;
        padding: 8px 12px;
        font-size: unset;
      }

      @include media(desktop, desktop-big, tabletLa) {
        margin: 15px 10px;
        padding: 10px 20px;
        font-size: large;
      }
      .tpLvl:hover {
        background-color: $gr;
      }

      .lvlAct {
        background-color: $dG2;
        box-shadow: none;
      }
    }
    .OaHddiv {
      margin-block: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .OaHdTxt {
        font-size: larger;
        margin-inline: 20px;
        font-weight: 600;
      }
    }
  }
}

.mockEaMain {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .RpTxt {
    background-color: $Wpnk;
    color: $pnk;
    border-radius: 20px;
    box-shadow: 0 0 10px $Wpnk;
    padding: 15px 30px;
    font-size: larger;
    text-align: center;
    font-weight: 600;
  }
  .mockEaDiv {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .eaCongTxt {
      text-align: center;
      font-size: large;
      background-color: $lG;
      box-shadow: 0 0 5px $lG;
      padding: 10px;
      color: $w;
      border-radius: 10px;
    }
    .eahdTxt {
      padding: 15px 15px;
      background: linear-gradient(90deg, $Wpnk 0%, $w 50%, $GW 100%);
      border-radius: 20px;
      font-weight: 600;
      font-size: larger;
      box-shadow: 0 0 10px $pnk;
      color: $gr;
    }
    .eaParaTxt {
      padding: 20px;
      background-color: $GW;
      box-shadow: 0 0 10px $GW;
      border-radius: 10px;
      font-size: large;
    }
    .topItmDiv {
      display: flex;
      max-width: 100%;
      flex-wrap: wrap;
      // justify-content: space-around;
      padding: 10px;
      background-color: $Wpnk;
      border-radius: 20px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      margin-block: 10px;
      @include media(mobile, tabletPo) {
        justify-content: space-around;
      }

      @include media(desktop, desktop-big, tabletLa) {
        justify-content: unset;
      }
      .topItm {
        cursor: pointer;
        background-color: $Wpnk;
        padding: 10px 20px;
        font-size: large;
        margin-inline: 15px;
        border-radius: 20px;
        color: $dG2;
        border-color: 1px solid $pnk;
        box-shadow: 0 0 10px $pnk;
        transition: 600ms;
        @include media(mobile, tabletPo) {
          box-shadow: 0 0 5px $pnk;
          font-size: unset;
          padding: 5px 10px;
          margin: 8px;
        }

        @include media(desktop, desktop-big, tabletLa) {
          box-shadow: 0 0 10px $pnk;
          font-size: large;
          padding: 10px 20px;
          margin: 15px;
        }
      }
      .topItm:hover {
        background-color: $pnk;
        color: $w;
      }
    }
    .eaAcBtn {
      align-self: flex-start;
      cursor: pointer;
      background-color: $dGr;
      box-shadow: 0 0 10px $dGr;
      padding: 15px 30px;
      font-size: large;
      border-radius: 20px;
      color: $w;
      margin-block: 15px;
      transition: 600ms;
    }
    .mbt {
      background-color: $pnk;
      box-shadow: 0 0 10px $pnk;
    }
    .eaAcBtn:hover {
      box-shadow: none;
    }
  }
}

.mockProgMain {
  display: flex;
  justify-content: center;
  align-items: center;
  .progHead {
    background-color: $Wpnk;
    color: $pnk;
    border-radius: 20px;
    box-shadow: 0 0 10px $Wpnk;
    padding: 15px 30px;
    font-size: larger;
    text-align: center;
    font-weight: 600;
  }
}
