@import "./config.scss";

.sidebar {
  user-select: none;
  font-family: sans-serif;
  width: 300px;
  background: $w;
  display: flex;
  flex-direction: column;
  .logoD {
    width: 260px;
    background: $w;
    position: fixed;
    top: 0;
    padding-block: 10px;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    .logo {
      height: 75px;
      cursor: pointer;
      object-fit: contain;
    }
  }
  .usrProfile {
    padding-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid #000;
    .usrImg {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      object-fit: contain;
      width: 40%;
      aspect-ratio: 1;
      border-radius: 100px;
      margin-bottom: 10px;
    }
    .usrName {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
      .alt {
        font-size: small;
        font-weight: 500;
      }
    }
    .btnDiv {
      display: flex;
      width: 100%;
      justify-content: space-around;
      .proBtn {
        font-size: 14px;
        cursor: pointer;
        background-color: $pnk;
        padding: 10px 10px;
        border-radius: 20px;
        color: $w;
        box-shadow: 0 0 10px $pnk;
        transition: 600ms ease;
      }
      .proBtn:hover {
        box-shadow: none;
        background-color: $dGr;
      }
    }
  }
  .menu {
    border-bottom: 0.5px solid #00000020;
    display: flex;
    flex-direction: column;
    .menuItmD {
      cursor: pointer;
      transition: 600ms ease;
      background-color: $w;
      padding-inline: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .menuItm {
        // margin-block: 15px;
        color: $b;
        text-decoration: none;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        .menuIcn {
          color: $lG;
          font-size: x-large;
        }
      }
      .menuOpn {
        color: $dGr;
        font-size: large;
      }
    }
    .menuItmD:hover {
      background-color: $Wpnk;
    }
    .subItm {
      cursor: pointer;
      transition: 600ms ease;
      padding-left: 10px;
      .lstItm {
        transition: 600ms ease;
        margin: 4px;
        padding: 10px;
        background-color: $GW;
        font-size: 14px;
      }
      .lstItm:hover {
        background-color: $gr;
        color: $w;
      }
    }
  }

  .resMn {
    @include media(mobile, tabletPo, tabletLa) {
      display: unset;
    }
    @include media(desktop, desktop-big) {
      display: none;
    }
  }
}

.NaSidebar {
  user-select: none;
  font-family: sans-serif;
  width: 260px;
  background: $w;
  display: flex;
  flex-direction: column;
}
