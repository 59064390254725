@import "./config.scss";

.AiMain {
  margin-top: 100px;
  user-select: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .plrTxt {
    align-self: center;
    font-size: x-large;
    font-weight: 600;
    text-align: center;
    padding: 15px 30px;
    border-radius: 20px;
    color: $pnk;
    background-color: $Wpnk;
    box-shadow: 0 0 20px $Wpnk;
    margin: 10px;
  }
  .uPrExmCon {
    margin: 20vh 5vw 40vh 5vw;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .prBg {
      position: absolute;
      bottom: 0px;
      right: 0;
      @include media(mobile, tabletPo) {
        width: 200px;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: unset;
      }
    }
    .uPrExm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $w;
      border-radius: 40px;
      // box-shadow: 0 0 50px $lpnk90;
      padding: 10px 20px;
      .prTxt {
        text-align: center;
        font-size: 24px;
        font-weight: 500;
      }
      .ExmSelect {
        min-width: 200px;
        max-width: 400px;
        .Mui-focused .MuiOutlinedInput-notchedOutline {
          border: 1px solid $lG !important;
          box-shadow: 0 0 5px $lG;
        }
      }
      .nxtBtn {
        background-color: $dGr;
        text-decoration: none;
        color: $w;
        font-size: 18px;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 20px;
        box-shadow: 0 0 10px $dGr;
        transition: 600ms;
        margin: 20px 0 10px 0;
      }
      .nxtBtn:hover {
        box-shadow: none;
      }
    }
  }

  .wuCon {
    margin: 20vh 5vw 25vh 5vw;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media(mobile, tabletPo) {
      margin: 0vh 5vw 35vh 5vw;
    }

    @include media(desktop, desktop-big, tabletLa) {
      margin: 20vh 5vw 25vh 5vw;
    }
    .wuBg {
      position: absolute;
      bottom: 0px;
      right: 0;
      @include media(mobile, tabletPo) {
        width: 200px;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: unset;
      }
    }
    .wu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $w;
      border-radius: 40px;
      padding: 10px 20px;
      .wuTxt {
        font-family: childishfont;
        font-weight: 700;
        text-align: center;
        font-size: 60px;
        font-weight: 500;
      }
      .typeTxt {
        text-align: center;
        transition: 1000ms;
        font-size: 20px;
        font-weight: 500;
        font-family: cursive;
      }
      .wuBtnD {
        width: 300px;
        display: flex;
        margin-top: 10px;
        justify-content: space-around;
        align-items: center;
        .nxtBtn {
          background-color: $dGr;
          text-decoration: none;
          color: $w;
          font-size: 18px;
          font-weight: 600;
          padding: 10px 20px;
          border-radius: 20px;
          box-shadow: 0 0 10px $dGr;
          transition: 600ms;
          margin: 20px 0 10px 0;
        }
        .nxtBtn:hover {
          box-shadow: none;
        }
      }
    }
  }

  .edCon {
    margin: 20vh 5vw 40vh 5vw;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media(mobile, tabletPo) {
      margin: 0vh 5vw 60vh 5vw;
    }
    @include media(desktop, desktop-big, tabletLa) {
      margin: 20vh 5vw 40vh 5vw;
    }
    .edBg {
      position: absolute;
      bottom: 0px;
      right: 0;
      @include media(mobile, tabletPo) {
        width: 200px;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: unset;
      }
    }
    .ed {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $w;
      border-radius: 40px;
      // box-shadow: 0 0 50px $lpnk;
      padding: 10px 20px;

      .edTxt {
        text-align: center;
        font-size: 24px;
        font-weight: 500;
      }
      .date {
        margin: 10px;
        min-width: 200px;
        max-width: 400px;
        .Mui-focused .MuiOutlinedInput-notchedOutline {
          border: 1px solid $lG !important;
          box-shadow: 0 0 5px $lG;
        }
      }
      .epBtnD {
        display: flex;
        width: 100%;
        justify-content: space-around;
        .epBtn {
          text-align: center;
          width: 300px;
          padding: 15px;
          font-size: 20px;
          font-family: sans-serif;
          font-weight: 600;
          border-radius: 20px;
          background-color: $GW;
          box-shadow: 0 0 20px $dGr60;
          transition: 600ms;
        }
        .epBtn:hover {
          color: $w;
          background-color: $lpnk;
          box-shadow: none;
        }
        .epBtn-act {
          background-color: $gr;
          color: $w;
        }
      }
      .stwkDiv {
        width: 100vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .stCon,
        .wkCon {
          box-shadow: 0 0 10px $gr;
          border-radius: 20px;
          min-width: 300px;
          max-width: 400px;
          display: flex;
          flex-direction: column;
          margin: 10px;
          .st,
          .wk {
            border-radius: 20px;
            padding-block: 20px;
            text-align: center;
            color: $w;
            font-weight: 600;
            font-family: sans-serif;
            font-size: 20px;
            background-color: $gr;
          }
          .wk {
            background-color: $pnk;
          }
          .subList {
            max-height: 400px;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            padding: 20px;
          }
        }
        .wkCon {
          box-shadow: 0 0 10px $pnk;
        }
      }
      .nxtBtn {
        background-color: $dGr;
        text-decoration: none;
        color: $w;
        font-size: 18px;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 20px;
        box-shadow: 0 0 10px $dGr;
        transition: 600ms;
        margin: 20px 0 10px 0;
      }
      .nxtBtn:hover {
        box-shadow: none;
      }
    }
  }

  .cgCon {
    background: url("../Assets/ui/cong.gif");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media(mobile, tabletPo) {
      height: unset;
    }
    @include media(desktop, desktop-big, tabletLa) {
      height: 90vh;
    }
    .cgCong {
      font-family: cnrgt;
      font-size: 70px;
      @include media(mobile, tabletPo) {
        font-size: 60px;
      }
      @include media(desktop, desktop-big, tabletLa) {
        font-size: 100px;
      }
    }
    .cgBody {
      width: 60vw;
      display: flex;
      flex-direction: column;
      @include media(mobile, tabletPo) {
        width: 94vw;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 60vw;
      }
      .body {
        font-size: larger;
        font-weight: 100;
      }
      .cgTxtD {
        margin-block: 25px;
        align-self: flex-start;
        font-family: monospace;
        text-shadow: 6px 5px 5px rgba(0, 0, 0, 0.4);
        border-radius: 0px 200px 0px 200px;
        background-color: $pnk;
        box-shadow: 0px 5px 10px $lpnk;
        padding: 0px 25px 0px 25px;
        .cgTxt {
          text-align: center;
          font-size: x-large;
          font-weight: bold;
          color: $w;
          @include media(mobile, tabletPo) {
            font-size: larger;
          }

          @include media(desktop, desktop-big, tabletLa) {
            font-size: x-large;
          }
        }
      }
      .cgBtnD {
        cursor: pointer;
        align-self: center;
        box-shadow: 0 0 10px $gr;
        margin: 20px 5px;
        display: flex;
        align-items: center;
        border: 1px solid $gr;
        border-radius: 100px;
        transition: 600ms;
        .cgIcnD {
          border: 1px solid $gr;
          border-left: 0px;
          border-radius: 100px;
          padding: 8px;
          .cgIcn {
            background-color: $gr;
            padding: 15px;
            color: $w;
            border-radius: 100px;
          }
        }
        .cgBtnTxt {
          padding-inline: 10px;
          font-weight: 500;
          font-size: 18px;
          font-family: sans-serif;
        }
      }
      .cgBtnD:hover {
        box-shadow: none;
      }
    }
    .cgBg {
      width: 280px;
      right: 0px;
      bottom: 0px;
      position: absolute;
      @include media(mobile, tabletPo) {
        width: 180px;
        right: unset;
        bottom: unset;
        position: unset;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 280px;
        right: 0px;
        bottom: 0px;
        position: absolute;
      }
    }
  }

  .plrMain {
    width: 100vw;
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    .usrInfoD {
      align-self: center;
      padding-inline: 40px;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 20px;
      width: 60vw;
      border-radius: 24px;
      background-color: $GW;
      box-shadow: 0 0 10px $GW;
      display: flex;
      @include media(mobile, tabletPo) {
        width: 80vw;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: 70vw;
      }
      .infD {
        display: flex;
        flex-direction: column;
        width: 50%;
        @include media(mobile, tabletPo) {
          width: 100%;
        }

        @include media(desktop, desktop-big, tabletLa) {
          width: 45%;
        }
        .usrInfo,
        .infoA {
          font-size: 20px;
        }
        .infoA {
          font-weight: 600;
        }
      }
    }
    .subReportD {
      margin-block: 10px;
      background-image: url("../Assets/ui/s-bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      justify-content: center;
    }
    .dnBtn {
      text-decoration: none;
      font-size: 18px;
      align-self: center;
      background-color: $dGr;
      color: $w;
      padding: 10px 20px;
      border-radius: 20px;
      box-shadow: 0 0 10px $dGr;
      margin: 20px 10px;
      transition: 600ms;
    }
    .dnBtn:hover {
      box-shadow: none;
    }
  }

  .deAnalysisMain {
    margin-block: 20px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .usrInfoD {
      padding-inline: 40px;
      margin: 20px;
      width: 25vw;
      border-radius: 24px;
      background-color: $GW;
      box-shadow: 0 0 10px $GW;
      display: flex;
      flex-direction: column;
      @include media(mobile, tabletPo) {
        width: 90vw;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: 25vw;
      }
      .infD {
        display: flex;
        flex-direction: column;
        width: 100%;
        .usrInfo,
        .infoA {
          font-size: 20px;
        }
        .infoA {
          font-weight: 600;
        }
      }
    }
    .mockAn {
      cursor: pointer;
      background-color: $dGr;
      padding: 15px 20px;
      border-radius: 20px;
      color: $w;
      font-size: larger;
      box-shadow: 0 0 20px $gr;
      font-weight: 600;
      transition: 600ms;
    }

    .mockAn:hover {
      box-shadow: none;
    }
    .deImgDiv {
      width: 35vw;
      display: flex;
      justify-content: center;
      margin-block: 10px;
      @include media(mobile, tabletPo) {
        width: 90vw;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: 40vw;
      }
      .deImg {
        pointer-events: none;
        width: 50%;
        @include media(mobile, tabletPo) {
          width: 70%;
        }

        @include media(desktop, desktop-big, tabletLa) {
          width: 50%;
        }
      }
    }
    .deProgDiv {
      display: flex;
      flex-direction: column;
      width: 25%;
      padding-inline: 10%;
      align-items: center;
      @include media(mobile, tabletPo) {
        width: 94%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 25%;
      }
    }
    .MonRepDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-block: 10px;
      background-color: #d9edf650;
      .DeDtInputDiv {
        width: 80%;
        margin-block: 20px;
        display: flex;
        flex-wrap: wrap;
        box-shadow: 0 0 10px $lG;
        padding: 10px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        @include media(mobile, tabletPo) {
          width: 80%;
        }

        @include media(desktop, desktop-big, tabletLa) {
          width: unset;
        }
        .deInptLbl {
          font-size: large;
          font-weight: 600;
          padding-inline: 20px;
          margin-block: 0px 5px;
        }
        .date {
          .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
            height: 8px;
          }
        }
      }
      .monTiSp {
        font-size: larger;
        background-color: $w;
        box-shadow: 0 0 5px $lpnk;
        padding: 10px 20px;
        border-radius: 10px;
      }
      .gradDiv {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-block: 10px;
        .grad {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 20%;
          background: linear-gradient(
            60deg,
            $pnk 0%,
            $dG2 30%,
            $lG 60%,
            $GW 100%
          );
          aspect-ratio: 2;
          font-size: x-large;
          font-weight: 600;
          color: $w;
          border-radius: 20px;
          box-shadow: 10px 10px 20px $lpnk;
          @include media(mobile, tabletPo) {
            width: 25%;
            font-size: unset;
            aspect-ratio: 1;
            font-weight: unset;
          }

          @include media(desktop, desktop-big, tabletLa) {
            width: 20%;
            font-size: x-large;
            aspect-ratio: 2;
            font-weight: 600;
          }
        }
      }
    }
    .deTbl {
      margin-block: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tblSubDiv {
        background-color: $Wpnk;
        border-radius: 20px;
        padding: 10px;
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        .tblSubName {
          cursor: pointer;
          padding: 10px;
          background-color: $GW;
          margin: 10px;
          border-radius: 20px;
          box-shadow: 0 0 10px $gr;
          transition: 600ms;
          @include media(mobile, tabletPo) {
            margin: 6px;
          }
          @include media(desktop, desktop-big, tabletLa) {
            margin: 10px;
          }
        }
        .tblSubName:hover {
          background-color: $lpnk;
          box-shadow: 0 0 10px $pnk;
          color: $w;
        }
        .altSub,
        .altSub:hover {
          background-color: $pnk;
          color: $w;
          box-shadow: none;
          font-weight: 600;
          letter-spacing: 0.5px;
        }
      }
      .OaHddiv {
        align-self: center;
        margin-block: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .OaHdTxt {
          font-size: larger;
          margin-inline: 20px;
          font-weight: 600;
        }
      }
    }
  }
}
