@import "./config.scss";

.clmDiv {
  width: 380px;
  margin-inline: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .itmDiv {
    // cursor: pointer;
    width: 80%;
    margin-block: 10px;
    background-color: $w;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform 0.5s;
    box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.1);

    .itmImg {
      pointer-events: none;
      height: 200px;
      aspect-ratio: 1;
      object-fit: contain;
    }
    .itmHd {
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }
    .itmDesc {
      font-size: 14px;
      text-align: center;
    }
  }
  .itmDiv:hover {
    transform: scale(1.1);
  }
}

.crsCarousel {
  width: 100%;
  margin-bottom: 80px;
  .crsItem {
    margin: 40px;
    background-color: $w;
    border-radius: 10px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .crsImg {
      pointer-events: none;
      width: 94%;
      object-fit: contain;
      max-height: 300px;
      border-radius: 20px;
    }
    .offImg {
      pointer-events: none;
      width: 96%;
      object-fit: contain;
      max-height: 200px;
      border-radius: 20px;
    }
    .crsTitle {
      font-weight: 500;
      font-size: 18px;
      padding-top: 20px;
    }
  }
}

.rvwCarousel {
  width: 100%;
  .rvwItem {
    margin: 40px 6px;
    aspect-ratio: 1;
    background-color: $w;
    border-radius: 10px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 10px 20px;
    flex-direction: column;
    justify-content: space-between;
    @include media(mobile, tabletPo) {
      margin: 40px 6px;
    }

    @include media(desktop, desktop-big, tabletLa) {
      margin: 40px 40px;
    }
    .rvwImg {
      pointer-events: none;
      width: 120px;
      align-self: center;
      aspect-ratio: 1;
      object-fit: contain;
      border-radius: 100px;
      border: 1px solid $b;
    }
    .colon {
      font-size: 50px;
      color: #f76a8a;
    }
    .rvwName {
      font-weight: 500;
      font-size: 18px;
    }
    .rvw {
      font-size: 14px;
    }
  }
}

.teamCarousel {
  width: 100%;
  .teamItem {
    aspect-ratio: 1;
    border-radius: 10px;
    display: flex;
    padding: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: center;
    .teamImg {
      pointer-events: none;
      width: 250px;
      border-radius: 200px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    .teamMmbr {
      margin-block: 10px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.courseCrsl {
  width: 100%;
  .corsItem {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    aspect-ratio: 2;
    padding: 10px;
    margin: 10px;
    border-radius: 20px;
    border-radius: 5px;
    .corsImg {
      pointer-events: none;
      height: 100%;
      border-radius: 10px;
      width: 100%;
    }
  }
}

.ebGnrsMain {
  margin-block: 20px;
  user-select: none;
  width: 90%;
  display: flex;
  flex-direction: column;
  .ebGnrsHd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid $dG2;
    .gnrTtl {
      background-color: $dG2;
      color: $w;
      padding: 10px;
      font-size: 20px;
      font-family: sans-serif;
    }
    .gnrVwAll {
      cursor: pointer;
      font-size: large;
      color: $dG2;
      font-weight: 600;
      font-family: sans-serif;
    }
    .gnrVwAll:hover {
      color: #f76a8a;
    }
  }
}

.subsOfferCrsl {
  align-self: center;
  display: flex;
  width: 40%;
  .subOItem {
    display: flex;
    justify-content: center;
    padding: 2px;
    border-radius: 10px;
    box-shadow: 0 0 10px #108d9877;
    .subOImg {
      pointer-events: none;
      pointer-events: none;
      border-radius: 10px;
      width: 100%;
      object-fit: contain;
    }
  }
}

.vidCsrlMain {
  display: flex;
  flex-direction: column;
  width: 98%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-block: 10px;
  border-radius: 20px;
  .vidHeadMain {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 20px 20px 0 0;
    background-color: $gr;
    .vidTtl {
      text-align: center;
      width: 80%;
      padding-left: 10%;
      color: $w;
      font-weight: 400;
      @include media(mobile, tabletPo) {
        font-size: unset;
      }

      @include media(desktop, desktop-big, tabletLa) {
        font-size: larger;
      }
    }
    .vidVwAlBtn {
      cursor: pointer;
      text-align: center;
      padding: 10px;
      width: 100px;
      margin-right: 5px;
      justify-self: flex-end;
      color: $dGr;
      font-weight: 600;
      background-color: $GW;
      border-radius: 20px;
      box-shadow: 0 0 10px $GW;
      transition: 600ms;
      @include media(mobile, tabletPo) {
        padding: 6px;
        margin-right: 10px;
      }

      @include media(desktop, desktop-big, tabletLa) {
        padding: 10px;
        margin-right: 5px;
      }
    }
    .vidVwAlBtn:hover {
      box-shadow: none;
    }
  }
}

.exmUpdtMain {
  display: flex;
  flex-direction: column;
  width: 30%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin-block: 20px;
  border-radius: 20px;
  @include media(mobile, tabletPo) {
    width: 90%;
  }
  @include media(desktop, desktop-big, tabletLa) {
    width: 30%;
  }
  .exmHeadDiv {
    width: 100%;
    margin-block: 0px;
    padding-block: 20px;
    border-radius: 20px 20px 0 0;
    background-color: $gr;
    font-size: larger;
    font-weight: 600;
    color: $w;
    text-align: center;
  }
  .exmInfoDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    .exmName {
      text-align: center;
      font-size: x-large;
      font-weight: 600;
      margin-bottom: 4px;
    }
    .lstDate {
      text-align: center;
      font-size: larger;
      .lstDate-alt {
        font-size: large;
      }
    }
    .exmBtn {
      cursor: pointer;
      background-color: $GW;
      padding: 10px 20px;
      border-radius: 20px;
      border: 1px solid $dGr;
      box-shadow: 0 0 10px $dGr;
      color: $dGr;
      font-weight: 600;
      transition: 600ms;
    }

    .exmBtn:hover {
      background-color: $dGr;
      color: $GW;
      box-shadow: none;
    }
  }
}

.disQuesMain {
  width: 55%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin-block: 20px;
  border-radius: 20px;
  @include media(mobile, tabletPo) {
    width: 90%;
  }
  @include media(desktop, desktop-big, tabletLa) {
    width: 55%;
  }
  .disHeadMain {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 20px 20px 0 0;
    background-color: $gr;
    .quesTtl {
      text-align: center;
      width: 75%;
      padding-left: 10%;
      color: $w;
      font-weight: 600;
      font-size: larger;
    }
    .qVwBtn {
      margin-right: 5px;
      text-align: center;
      padding: 10px;
      width: 100px;
      justify-self: flex-end;
      color: $dGr;
      font-weight: 600;
      background-color: $GW;
      border-radius: 20px;
      box-shadow: 0 0 20px $GW;
      transition: 600ms;
    }
    .qVwBtn:hover {
      box-shadow: none;
    }
  }
  .quesBody {
    padding: 20px 20px 0px 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .usrImg {
      pointer-events: none;
      width: 10%;
      border-radius: 100px;
      border: 1px solid $b;
      @include media(mobile, tabletPo) {
        width: 20%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 10%;
      }
    }
    .quesD {
      width: 90%;
      display: flex;
      flex-direction: column;
      padding-inline: 10px;
      @include media(mobile, tabletPo) {
        width: 80%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 90%;
      }
      .usrName {
        font-size: large;
        font-weight: 600;
        color: $dGr;
        margin-block: 5px;
      }
      .usrQues {
        font-size: large;
        margin-bottom: 0;
      }
      .QvotesD {
        display: flex;
        .qIcnD {
          display: flex;
          align-items: center;
          margin-inline: 10px;
          .qLike {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            border: 1px solid $gr;
            padding: 8px;
            color: $gr;
            border-radius: 100px;
          }
          .qDisLike {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            border: 1px solid $pnk;
            padding: 8px;
            color: $pnk;
            border-radius: 100px;
          }
          .qCmmnt {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            border: 1px solid grey;
            padding: 8px;
            color: grey;
            border-radius: 100px;
          }
          .qNoti {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            border: 1px solid rgb(225, 225, 16);
            padding: 8px;
            color: rgb(225, 225, 16);
            border-radius: 100px;
          }
        }
      }
    }
  }
  .qRpBtn {
    background-color: $pnk;
    border-radius: 20px;
    padding: 10px 20px;
    align-self: flex-end;
    margin-right: 10px;
    color: $w;
    font-weight: 600;
    box-shadow: 0 0 20px $pnk;
    margin-top: 0;
  }
}

.nwsWid {
  display: flex;
  flex-direction: column;
  width: 30%;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  @include media(mobile, tabletPo) {
    width: 90%;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 30%;
  }

  .widHead {
    background-color: $GW;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $b;

    .nwsWdImg {
      pointer-events: none;
      width: 80px;
      object-fit: contain;
      border-radius: 100px;
    }

    .caTxt {
      font-size: 18px;
      font-weight: 600;
      text-align: center;

      .caTxt-alt {
        font-weight: 500;
        font-size: 16px;
      }
    }

    .caBtn {
      cursor: pointer;
      background-color: $dG2;
      padding: 10px;
      transition: 600ms;
      border-radius: 20px;
      color: $w;
      box-shadow: 0 0 10px $dG2;
    }

    .caBtn:hover {
      box-shadow: none;
    }
  }
  .nwsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    @include media(mobile, tabletPo) {
      flex-direction: column;
    }

    @include media(desktop, desktop-big, tabletLa) {
      flex-direction: row;
    }

    .nwsImgCon {
      display: flex;
      border-radius: 10px;
      aspect-ratio: 1;
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
      @include media(mobile, tabletPo) {
        width: 90%;
        height: 200px;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: 40%;
        height: unset;
      }
    }
    .nwsImg {
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      width: 100%;
      pointer-events: none;
      object-fit: contain;
    }

    .newsInfoD {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 60%;
      @include media(mobile, tabletPo) {
        width: 100%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 60%;
      }
      .nwsHead {
        padding-inline: 10px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 0px;
      }

      .nwsBody {
        padding-inline: 10px;
        text-align: center;
        margin-bottom: 5px;
      }

      .NwsBtn {
        cursor: pointer;
        background-color: $GW;
        padding: 10px 20px;
        border-radius: 20px;
        border: 1px solid $dGr;
        box-shadow: 0 0 10px $dGr;
        color: $dGr;
        font-weight: 600;
        transition: 600ms;
        &:hover {
          background-color: $dGr;
          color: $GW;
          box-shadow: none;
        }
      }
    }
  }
}

.bnrCrsl {
  align-self: center;
  width: 80%;
  object-fit: contain;
  overflow: hidden;
  border-radius: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  margin-block: 10px;
  @include media(mobile, tabletPo) {
    width: 94%;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 80%;
  }
}

.NaHdMain {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  @include media(mobile, tabletPo) {
    width: 94%;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 80%;
  }
  .navArDiv {
    cursor: pointer;
    display: flex;
    background-color: $lpnk;
    color: $w;
    padding: 20px;
    border-radius: 100px;
    box-shadow: 0 0 10px $pnk;
    transition: 600ms;
    font-size: large;
    @include media(mobile, tabletPo) {
      display: none;
    }

    @include media(desktop, desktop-big, tabletLa) {
      display: flex;
    }
  }
  .navArDiv:hover {
    box-shadow: none;
    background-color: $pnk;
  }
  .naCrsl {
    width: 100%;
    display: flex;
    align-items: center;
    .NaItm {
      border-radius: 20px;
      background-color: $w;
      box-shadow: 0 0 10px $gr;
      display: flex;
      width: 96%;
      flex-direction: column;
      justify-content: space-between;
      height: 80vh;
      align-items: center;
      margin: 10px 20px;
      overflow: hidden;
      background-repeat: no-repeat !important;
      background-size: 100% 46% !important;
      @include media(mobile, tabletPo) {
        margin: 5px 5px;
        width: 98%;
      }
      @include media(desktop, desktop-big, tabletLa) {
        margin: 10px 20px;
        width: 94%;
      }
      .naBtnDiv {
        height: 38%;
        width: 100%;
        justify-content: space-around;
        display: flex;
        align-items: flex-start;
        .naImg {
          -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
          width: 100%;
          height: 100%;
          object-fit: contain;
          padding-bottom: 15%;
          border-radius: 10px;
        }
        .naBmIcn {
          position: absolute;
          right: 4%;
          top: 2%;
          cursor: pointer;
          color: $gr;
          background-color: $GW;
          box-shadow: 0 0 10px $gr;
          padding: 6px;
          border-radius: 100px;
          transition: 600ms;
        }
        .naBmIcn:hover {
          box-shadow: none;
        }
      }
      .naBdDiv {
        display: flex;
        flex-direction: column;
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
        height: 65%;
        background-color: $w;
        border-radius: 50px 50px 20px 20px;
        box-shadow: 0 0 10px #ffffff;
        .naHd {
          text-align: center;
          font-size: larger;
          font-weight: 600;
          padding-inline: 4%;
          @include media(mobile, tabletPo) {
            font-size: unset;
          }

          @include media(desktop, desktop-big, tabletLa) {
            font-size: large;
          }
        }
        .naBd {
          text-align: justify;
          margin-bottom: 0px;
          padding-inline: 2%;
          margin-block: 10px;
        }
        .naSrc {
          background-color: $Wpnk;
          padding: 6px;
          align-self: flex-start;
          color: #00000070;
          border-radius: 10px;
          margin-left: 10px;
          font-size: small;
        }
        .naDt {
          background-color: $GW;
          padding: 6px 10px;
          margin-right: 10px;
          align-self: flex-end;
          border-radius: 10px;
          font-weight: 600;
          letter-spacing: 0.4px;
        }
        .naBtn {
          text-align: center;
          cursor: pointer;
          background-color: $lpnk;
          color: $w;
          padding: 10px 20px;
          font-size: large;
          font-weight: 600;
          letter-spacing: 0.4px;
          border-radius: 20px;
          box-shadow: 0 0 10px $pnk;
          transition: 600ms;
          margin-left: 20px;
          @include media(mobile, tabletPo) {
            font-size: unset;
            padding: 6px 12px;
          }

          @include media(desktop, desktop-big, tabletLa) {
            font-size: large;
            padding: 10px 20px;
          }
        }
        .naBtn:hover {
          background-color: $pnk;
          box-shadow: none;
        }
      }
    }
    .alt {
      cursor: unset;
      opacity: 1;
    }
  }
}

.morVidCsrlMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-block: 20px 10px;
  .morVidHeadMain {
    display: flex;
    justify-content: center;
    .vidTtl {
      background-color: $w;
      padding: 10px 15px;
      font-weight: bold;
      border-radius: 20px;
      color: $pnk;
      box-shadow: 0 0 8px $gr;
      margin-block: 5px;
    }
  }
  .arr {
    cursor: pointer;
    color: $w;
    background-color: #f66b8a99;
    box-shadow: 0 0 10px $lpnk;
    padding: 10px;
    aspect-ratio: 1;
    border-radius: 100px;
    transition: 600ms;
  }
  .arr:hover {
    box-shadow: none;
    background-color: $pnk;
  }
}

.arr {
  font-size: 24px;
  cursor: pointer;
  color: $w;
  background-color: #f66b8a99;
  box-shadow: 0 0 10px $lpnk;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 100px;
  transition: 600ms;
}
.arr:hover {
  box-shadow: none;
  background-color: $pnk;
}
