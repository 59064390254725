@import "./config.scss";

.HomeD {
  font-family: sans-serif;
  user-select: none;
  margin-top: 100px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 84px;
  .DashMain {
    display: flex;
    flex-direction: column;
    width: 100%;
    .rowDiv {
      width: 100%;
      margin-block: 20px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
    }
    .wlcmTxtD {
      display: flex;
      flex-direction: column;
      @include media(mobile, tabletPo) {
        width: 340px;
      }
      @include media(desktop, desktop-big, tabletLa) {
        width: unset;
      }
      .wlcmTxt {
        text-align: center;
        font-family: sans-serif;
        font-size: 40px;
        color: $pnk;
        font-weight: 700;
        margin-block: 10px;
      }
      .keepTxt {
        text-align: center;
        font-size: larger;
      }
      .cmpltAiD {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-block: 20px;
        padding-inline: 20px;
        background-color: $GW;
        box-shadow: 0 0 10px $gr;
        border-radius: 20px;
        text-align: center;
        .cmpTxt {
          font-size: 18px;
          margin-bottom: 5px;
        }
        .cmpBtn {
          cursor: pointer;
          background-color: $pnk;
          padding: 10px 20px;
          color: $w;
          font-size: 18px;
          border-radius: 20px;
          box-shadow: 0 0 20px $pnk;
          transition: 600ms ease;
        }
        .cmpBtn:hover {
          box-shadow: none;
        }
      }
    }
    .wlcmImgD {
      .wlcmImg {
        pointer-events: none;
        margin-bottom: 20px;
        @include media(mobile, tabletPo) {
          width: 340px;
        }

        @include media(desktop, desktop-big, tabletLa) {
          width: 600px;
          object-fit: contain;
        }
      }
    }
  }
}

.remCrsMain {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  .remHead {
    text-align: center;
    margin-block: 20px;
    background-color: $w;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: x-large;
    font-weight: 600;
    color: $dG2;
    box-shadow: 0 0 10px $lpnk;
    margin-bottom: 10px;
    @include media(mobile, tabletPo) {
      font-size: medium;
    }
    @include media(desktop, desktop-big, tabletLa) {
      font-size: x-large;
    }
  }
}
