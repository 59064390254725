@import "./config.scss";

.topicMain {
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  .subName {
    text-align: center;
    margin-block: 20px;
    background-color: $w;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: x-large;
    font-weight: 600;
    color: $dG2;
    box-shadow: 0 0 10px $lpnk;
    margin-bottom: 10px;
    @include media(mobile, tabletPo) {
      font-size: medium;
    }
    @include media(desktop, desktop-big, tabletLa) {
      font-size: x-large;
    }
  }
  .tpReadDiv {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
  }

  .rptModMain {
    display: flex;
    justify-content: space-evenly;
    width: 60%;
    border: 1px solid $GW;
    border-radius: 100px;
    box-shadow: 0 0 10px $GW;
    margin-block: 10px;
    @include media(mobile, tabletPo) {
      width: 90%;
    }
    @include media(desktop, desktop-big, tabletLa) {
      width: 60%;
    }
    .rptMod,
    .alt {
      border-radius: 100px;
      background-color: $GW;
      width: 40%;
      text-align: center;
      padding-block: 15px;
      font-size: larger;
      box-shadow: 0 0 10px $gr;
      transition: 500ms;
      cursor: pointer;
      @include media(mobile, tabletPo) {
        font-size: small;
      }

      @include media(desktop, desktop-big, tabletLa) {
        font-size: larger;
      }
    }
    .rptMod:hover {
      background-color: $Wpnk;
      box-shadow: 0 0 10px $pnk;
    }
    .alt,
    .alt:hover {
      background-color: $pnk;
      box-shadow: none;
      color: $w;
    }
  }
}

.tpcProgMain {
  display: flex;
  justify-content: center;
  align-items: center;
  .tpcHead {
    background-color: $Wpnk;
    color: $pnk;
    border-radius: 20px;
    box-shadow: 0 0 10px $Wpnk;
    padding: 15px 30px;
    font-size: larger;
    text-align: center;
    font-weight: 600;
  }
}
