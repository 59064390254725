$dGr: #096069;
$dGr60: #09606960;
$dG2: #108c98;
$gr: #17a2b7;
$lG: #65bfbf;
$GW: #d9edf6;
$pnk: #f66b8a;
$lpnk: #f389aa;
$Wpnk: #fcedf0;
$palW: #f4f1ea;
$w: #fff;
$b: #000;
$daB: #00577c;

$breakpoints: (
  mobile: 0px,
  tabletPo: 600px,
  tabletLa: 900px,
  desktop: 1200px,
  desktop-big: 1800px,
);

//  mixin to print out media queries (based on map keys passed)
@mixin media($keys...) {
  @each $key in $keys {
    @media (min-width: map-get($breakpoints, $key)) {
      @content;
    }
  }
}

@font-face {
  font-family: cnrgt;
  src: url("../Assets/fonts/alsscrp.ttf");
}

@font-face {
  font-family: childishfont;
  src: url("../Assets/fonts/HwylFawrHello-Y6Ev.ttf");
}
