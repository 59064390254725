@import "./config.scss";

.toDoMain {
  border-radius: 20px;
  margin-block: 20px;
  width: 55%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  @include media(mobile, tabletPo) {
    width: 90%;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 55%;
  }
  .todoHeadCon {
    background-color: $gr;
    border-radius: 20px;
    padding: 10px;
    .todoHead {
      margin-block: 0px;
      display: flex;
      align-items: center;
      color: $w;
      font-size: larger;

      .todoIcn {
        border: 1px solid #fff;
        padding: 10px;
        border-radius: 100px;
      }
    }
  }

  .lstDiv {
    padding: 10px;
    .lstItm {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;
      .taskD {
        width: 60%;
        @include media(mobile, tabletPo) {
          width: 100%;
        }

        @include media(desktop, desktop-big, tabletLa) {
          width: 60%;
        }
        .taskTxt {
          font-size: large;
          font-weight: 600;
          margin-block: 5px;
        }
      }
      .todoBtnDiv {
        width: 200px;
        display: flex;
        justify-content: space-between;
        @include media(mobile, tabletPo) {
          width: 80%;
        }
        @include media(desktop, desktop-big, tabletLa) {
          width: 200px;
        }
        .todoBtn {
          cursor: pointer;
          color: $w;
          background-color: $dG2;
          padding: 10px 10px;
          border-radius: 10px;
          margin-inline: 5px;
          box-shadow: 0 0 10px $dG2;
          transition: 500ms ease;
        }

        .todoBtn:hover {
          box-shadow: none;
        }

        .rd {
          text-transform: capitalize;
          background-color: $pnk;
          box-shadow: 0 0 10px $pnk;
        }
      }
    }
  }
}

.subProgWidMain {
  width: 30%;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  @include media(mobile, tabletPo) {
    width: 90%;
    margin-top: 20px;
  }
  @include media(desktop, desktop-big, tabletLa) {
    width: 30%;
    margin-top: 0px;
  }
  .progHeadDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    .headTxt {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      .headTxt-Alt {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .subListDiv {
    display: flex;
    flex-direction: column;
    padding: 25px;
    max-height: 400px;
    overflow: hidden scroll;
    .subItm {
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 20px;
      justify-content: space-between;
      padding: 4px 0px 4px 8px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      margin-block: 10px;
      transition: 600ms;
    }
    .subItm:hover {
      transform: scale(1.08);
    }
  }
}

.QSideM {
  width: 100%;
  display: flex;
  flex-direction: column;

  .usrDiv {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: $GW;
    box-shadow: 0 0 10px $GW;
    border-radius: 10px;
    .usrImg {
      pointer-events: none;
      width: 50px;
      border-radius: 100px;
      aspect-ratio: 1;
    }
    .usrName {
      margin: 0px 20px;
      font-size: large;
      font-weight: 600;
    }
  }
  .MoptDiv {
    padding: 10px;
    .Mopt {
      font-size: smaller;
      padding: 6px 10px;
      aspect-ratio: 1;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border-radius: 100px;
    }

    .nv {
      background-color: #d5d5d5;
    }

    .nAns {
      color: $w;
      background-color: #fc5107;
    }

    .ans {
      color: $w;
      background-color: #7ac021;
    }

    .nar {
      color: $w;
      background-color: #8b65bf;
    }

    .ar {
      background-color: #9999ff;
      color: $w;
    }
  }
  .quesIndexDiv {
    margin-block: 20px;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: rgb(245, 245, 245);
    box-shadow: inset 0 0 20px $w;
    .quesStTxt {
      text-align: center;
      margin-block: 5px;
      font-size: 18px;
    }
    .quesIndexLst {
      padding-block: 10px;
      display: flex;
      flex-wrap: wrap;
      max-height: 400px;
      overflow-y: scroll;
      .Mopt2 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        font-size: smaller;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 100px;
        margin: 6px;
        transition: 600ms;
      }
      .Mopt2:hover {
        box-shadow: none;
      }
    }
  }
}

.crsSubDiv {
  display: flex;
  flex-direction: column;
  width: 90%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin-block: 20px;
  border-radius: 20px;
  .crsSubHead {
    width: 100%;
    margin-block: 0px;
    padding-block: 20px;
    border-radius: 20px 20px 0 0;
    background-color: $gr;
    text-align: center;
    color: $w;
    font-weight: 600;
    font-size: larger;
    @include media(mobile, tabletPo) {
      font-size: unset;
      padding-block: 15px;
    }
    @include media(desktop, desktop-big, tabletLa) {
      font-size: larger;
      padding-block: 20px;
    }
  }
  .subItmDiv {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .subItm {
      cursor: pointer;
      display: flex;
      background-color: $Wpnk;
      border: 1px solid $pnk;
      box-shadow: 0 0 10px $pnk;
      border-radius: 15px;
      margin: 15px;
      flex-direction: column;
      padding: 10px;
      transition: 600ms;
      @include media(mobile, tabletPo) {
        margin: 8px;
        padding: 8px;
        width: 80px;
      }
      @include media(desktop, desktop-big, tabletLa) {
        margin: 15px;
        padding: 10px;
        width: unset;
      }
      .sub {
        text-align: center;
        font-size: larger;
        color: $pnk;
        font-weight: 600;
        margin: 10px;
        @include media(mobile, tabletPo) {
          font-size: small;
          margin: 4px;
        }
        @include media(desktop, desktop-big, tabletLa) {
          font-size: larger;
          margin: 10px;
        }
      }
      .subIcn {
        font-size: large;
        color: $Wpnk;
        background-color: $pnk;
        align-self: center;
        padding: 10px;
        border-radius: 100px;
        @include media(mobile, tabletPo) {
          font-size: small;
          padding: 6px;
        }

        @include media(desktop, desktop-big, tabletLa) {
          font-size: large;
          padding: 10px;
        }
      }
    }
    .subItm:hover {
      transform: scale(1.15);
    }
  }
}

.prWidMain {
  display: flex;
  flex-direction: column;
  width: 40%;
  border-radius: 20px;
  box-shadow: 0 0 10px $lpnk;
  background-color: #d9edf660;
  margin-block: 20px;
  padding: 1%;
  align-items: center;
  @include media(mobile, tabletPo) {
    width: 90%;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 40%;
  }
  .prImg {
    pointer-events: none;
    padding: 4px;
    max-height: 150px;
    background-color: $w;
    aspect-ratio: 1;
    object-fit: contain;
    border-radius: 100px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    @include media(mobile, tabletPo) {
      max-height: 120px;
    }

    @include media(desktop, desktop-big, tabletLa) {
      max-height: 150px;
    }
  }
  .uName,
  .uId {
    font-weight: 600;
    font-size: x-large;
    border-radius: 200px;
    padding: 8px 15px;
    color: $pnk;
    margin: 0px;
    @include media(mobile, tabletPo) {
      font-size: large;
    }

    @include media(desktop, desktop-big, tabletLa) {
      font-size: x-large;
    }
  }
  .uId {
    font-size: large;
    align-self: center;
    background-color: $lpnk;
    color: $w;
    margin-bottom: 5px;
    @include media(mobile, tabletPo) {
      font-size: unset;
    }

    @include media(desktop, desktop-big, tabletLa) {
      font-size: large;
    }
  }
  .prRow {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $GW;
    box-shadow: 0 0 8px $lpnk;
    border-radius: 100px;
    padding-inline: 5%;
    background-color: $GW;
    margin-block: 10px;
    .prHd,
    .prD {
      margin-block: 10px;
      font-size: large;
      font-weight: 600;
      @include media(mobile, tabletPo) {
        font-size: unset;
      }

      @include media(desktop, desktop-big, tabletLa) {
        font-size: large;
      }
    }
    .prD {
      color: $dG2;
    }
  }
  .editBtn {
    cursor: pointer;
    padding: 8px 15px;
    background-color: $dGr;
    color: $w;
    border-radius: 20px;
    box-shadow: 0 0 10px $dGr;
    transition: 600ms;
    font-size: large;
    margin-bottom: 5px;
  }
  .editBtn:hover {
    box-shadow: none;
  }
}

.uActMain {
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0 0 12px $lG;
  background-color: #fcedf060;
  margin-block: 20px;
  align-items: center;
  padding-bottom: 20px;
  .usActHd {
    margin-top: 0px;
    background-color: $pnk;
    width: 100%;
    text-align: center;
    border-radius: 25px 25px 0px 0px;
    padding-block: 15px;
    color: $w;
    font-weight: 600;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media(mobile, tabletPo) {
      font-size: large;
    }

    @include media(desktop, desktop-big, tabletLa) {
      font-size: larger;
    }
    .mCrs {
      margin: 0px;
      padding-block: 0px;
      width: 80%;
      padding-block: 0px;
    }
    .crsIcn {
      cursor: pointer;
      aspect-ratio: 1;
      padding: 8px;
      background-color: $gr;
      color: $w;
      border: 2px solid #fff;
      margin-inline: 4px;
      border-radius: 200px;
      box-shadow: 0 0 5px $gr;
      transition: 500ms;
      font-size: 16px;
    }
    .crsIcn:hover {
      background-color: $pnk;
      box-shadow: unset;
    }
  }
  .actItm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    max-height: 260px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .actRow {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $Wpnk;
    box-shadow: 0 0 8px $lG;
    border-radius: 100px;
    padding-inline: 5%;
    background-color: $Wpnk;
    margin-block: 10px;
    .actHd,
    .actD {
      margin-block: 10px;
      font-size: large;
      font-weight: 600;
      color: $dG2;
      @include media(mobile, tabletPo) {
        font-size: unset;
      }
      @include media(desktop, desktop-big, tabletLa) {
        font-size: large;
      }
    }
    .actD {
      color: $pnk;
    }
  }
}

.atntnTpcDiv {
  display: flex;
  flex-direction: column;
  width: 40vw;
  box-shadow: 0 0 10px $gr;
  margin-block: 20px;
  align-items: center;
  border-radius: 25px;
  @include media(mobile, tabletPo) {
    width: 90vw;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 40vw;
  }
  .tpcHead {
    text-align: center;
    width: 100%;
    margin-block: 0px;
    background-color: $lG;
    padding-block: 10px;
    font-size: larger;
    font-weight: 600;
    border-radius: 20px;
    color: $w;
    box-shadow: 0 0 10px $gr;
  }
  .tpcMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 45vh;
    overflow: hidden scroll;
  }
  .tpItm {
    display: flex;
    flex-direction: column;
    width: 90%;
    .subName {
      align-self: center;
      background-color: $pnk;
      color: $w;
      padding: 8px 15px;
      border-radius: 20px;
      margin-block: 10px 0px;
      font-size: large;
      font-weight: 600;
      letter-spacing: 0.6px;
    }
    .tpcDiv {
      display: flex;
      flex-direction: column;
      width: 100%;
      box-shadow: 0 0 10px $lpnk;
      margin-block: 10px;
      border-radius: 20px;
      align-items: center;
      .tpc {
        background-color: $Wpnk;
        border-radius: 20px;
        margin: 0px;
        width: 90%;
        text-align: center;
        margin-block: 5px;
        padding-block: 5px;
      }
    }
  }
}

.QzRsltDiv {
  display: flex;
  flex-direction: column;
  background-color: #d9edf640;
  box-shadow: 0 0 10px $gr;
  width: 700px;
  margin-block: 40px 20px;
  border-radius: 20px;
  padding: 10px;
  align-items: center;
  .qzHead {
    color: $pnk;
    font-size: xx-large;
    font-weight: 600;
    margin: 10px;
  }
  .qzPtDiv {
    width: 55%;
    align-items: center;
    display: flex;
    background-color: $Wpnk;
    border-radius: 20px;
    margin-block: 10px;
    box-shadow: 0 0 10px $gr;
    padding-inline: 10%;
    .qzHd {
      margin-block: 15px;
      width: 60%;
      font-size: large;
      letter-spacing: 0.4px;
    }
    .qzBd {
      margin-block: 15px;
      width: 45%;
      font-size: large;
      color: $gr;
      font-weight: 600;
      text-align: center;
    }
  }
  .rsmBtn,
  .exBtn {
    background-color: $dGr;
    color: $w;
    border-radius: 20px;
    padding: 10px 20px;
    box-shadow: 0 0 10px $gr;
    cursor: pointer;
    transition: 600ms;
  }
  .rsmBtn:hover,
  .exBtn:hover {
    box-shadow: none;
  }
  .exBtn {
    background-color: $pnk;
    box-shadow: 0 0 10px $lpnk;
  }
}
